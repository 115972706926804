export const whiteColor = "#FFFFFF";
export const grayColor = "#808080";
export const grayColorOpacity = "#C9C3C9";
export const purpleColor = "#052656";
export const purpleColorOpacity = "#0092FF";
export const blackColor = "#444444";
export const orangeColor = "#0092FF";
export const orangeColorOpacity = "#FFEAAB"
export const redColor = "#BF4D47";
export const greenColor = "#006978";
export const seeWaterColor = "#19CCCC";
export const seeWaterColorOpacity = "#75e0e0";
export const blueColor = "#2E4D5B";
export const blueColorOpacity = "#608291";
export const magentaColor = "#AC2A72";
export const magentaColorOpacity = "#DDAAC6";
export const violetColor = "#8D529B";
export const violetColorOpacity = "#A9A7BC";


const purpleGradient = "linear-gradient(90deg, rgba(133,98,133,1) 0%, rgba(140,104,140,1) 50%, rgba(110,82,110,1) 100%)";
const orangeGradient = "linear-gradient(90deg, rgba(255,176,0,1) 0%, rgba(255,159,0,1) 50%, rgba(255,152,0,1) 100%)";
const blackGradient = "linear-gradient(90deg, rgba(77,77,77,1) 0%, rgba(77,77,77,1) 50%, rgba(51,51,51,1) 100%)";

const list = [
  { color: whiteColor, opacity: "" },
  { color: grayColor, opacity: "" },
  { color: purpleColor, opacity: purpleColorOpacity, gradient: purpleGradient },
  { color: blackColor, opacity: "" , gradient: blackGradient },
  { color: orangeColor, opacity: orangeColorOpacity, gradient: orangeGradient },
  { color: redColor, opacity: "" },
  { color: greenColor, opacity: "" },
  { color: seeWaterColor, opacity: seeWaterColorOpacity },
  { color: blueColor, opacity: blueColorOpacity }
];

export function getOpacityColor(color) {
  const response = list.find(x => x.color === color);
  return response ? response.opacity : whiteColor;
}

export function getGradientColor(color) {
  const response = list.find(x => x.color === color);
  return response ? response.gradient : color;
}

export const notificationOkColor = {
  background: purpleGradient,
  color: whiteColor,
  iconColor: whiteColor
}

export const notificationWarningColor = {
  background: orangeGradient,
  color: blackColor,
  iconColor: whiteColor
}

export const notificationErrorColor = {
  background: blackGradient,
  color: whiteColor,
  iconColor: whiteColor
}