import { Modal } from "antd";
import Button from '../Button/Button';
import propTypes from 'prop-types'
import { useTranslation } from "react-i18next";

const ModalConfirmation = ({visible, title, onOk, onCancel, okBtnText, cancelBtnText, children, open}) => {

  const [t] = useTranslation("modal");

  return (
    <Modal
      centered
      open={open}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key={1} type="secondary" onClick={onCancel}>
          {cancelBtnText ? cancelBtnText : t("btn-cancel")}
        </Button>,
        <Button key={2} type="optional" onClick={onOk}>
          {okBtnText ? okBtnText : t("btn-ok")}
        </Button>
      ]}
    >
      {children}
    </Modal>
  );
}

ModalConfirmation.propTypes = {
  open: propTypes.bool.isRequired,
  title: propTypes.string,
  onOk: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
  okBtnText: propTypes.string,
  cancelBtnText: propTypes.string
}

export default ModalConfirmation;