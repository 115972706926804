import CardCustom from "../CardCustom/CardCustom";
import { Row, Col, Switch, Avatar, Drawer, Form, Popconfirm, InputNumber } from 'antd';
import styles from "./CompanyCard.module.css";
import { orangeColor, grayColorOpacity, getGradientColor } from '../../utils/colorsCss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faPlanetRinged, faPlus, faWallet } from '@fortawesome/pro-solid-svg-icons'
import { useState, useEffect } from "react";
import Button from '../Button/Button';
import BalanceApi from "../../Api/BalanceApi";
import useSpinner from "../../Hooks/useSpinner";
import TYPES from "../../reducers/types";
import CONSTANTS, { CHANELLIST } from "../../utils/const";
import useAuth from "../../Hooks/useAuth";
import { currencyFormat, notificationError, notificationOk } from "../../utils/helpers";
import UpdateCompanyRates from "../UpdateCompanyRates/UpdateCompanyRates";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import RatesApi from "../../Api/RatesApi";
import CompanyApi from "../../Api/CompanyApi";

const CompanyCard = ({company, onAddCompanyBalance, companyId}) => {  
  const [t] = useTranslation("company");
  const navigate = useNavigate();

  const [ enableUser, setEnableUser, setEnableCompany ] = useState(company.enable);
  const [ showBalanceDrawer, setShowBalanceDrawer] = useState(false);
  const [ showUpdateCompanyRates, setShowUpdateCompanyRates] = useState(false);
  const [ balance, setBalancer] = useState();
  const [ form ] = Form.useForm();

  const { getCompanyTotalBalance } = BalanceApi();
  const { updateRates } = RatesApi();
  const { auth } = useAuth();

  const { updateAvailableCompany } = CompanyApi();



 
  const onClickUserSettings = (switchValue) => {
    console.log("switchValue before update:", switchValue);
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.USERS }
    });
    console.log("descripcion" , company.companyId)
    updateAvailableCompany(company.companyId, switchValue)
      .then((response) => {
        console.log("response" + response)
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
        }); 
        setEnableUser(switchValue);
        notificationOk(t("update-company.notification-available-company-ok"));
      })
      .catch(error => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
        });
        console.error(error);
        notificationError(t("update-company.notification-available-company-error"));
      });
  };


  const { spinnerDispacth } = useSpinner();

  const drawerBalanceVisible = () => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.GETBALANCECOMPANY }
    });

    getCompanyTotalBalance(company.companyId)
    .then(response => {

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETBALANCECOMPANY }
      });

      setShowBalanceDrawer(true);
      setBalancer(response);
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETBALANCECOMPANY }
      });
      console.error(error);
    })
  }

  const onUpdateCompanyRates = (data) => {

    if(data.fileSms.length === 0 && data.fileVoice.length === 0) {
      notificationError("There are not files to update rates");
      return;
    }
    
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.UPDATERATES }
    });

    updateRatesProcess(data)
      .then(response => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.UPDATERATES }
        });

        const { error } = response;

        if (error.length > 0) {
          notificationError(error);
        } else {
          notificationOk("The rates was update successful");
        }
       
        setShowUpdateCompanyRates(false);
      })
      .catch(error => {
        console.log(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.UPDATERATES }
        });
        notificationError("An error ocurred updating rates")
        setShowUpdateCompanyRates(false);
      })
  }

  const updateRatesProcess = async (data) => {

    let smsResponse = {};
    let voiceResponse = {}
    let messages  = [];
    
    if(data.fileSms && data.fileSms.length > 0) {

      const formData = new FormData();
      formData.append('files[]', data.fileSms[0]);

      const json = JSON.stringify({ CompanyId: company.companyId, ChannelId: CHANELLIST.SMS.key});
      const blob = new Blob([json], {
        type: 'application/json'
      });

      formData.append("data", blob);

      smsResponse = await updateRates(formData);
      if (!smsResponse.isSuccessful) {
        messages.push(`SMS: ${smsResponse.error}`);
      }
    }

    if(data.fileVoice && data.fileVoice.length > 0) { 
      console.log("File Voice")
      const formData = new FormData();
      formData.append('files[]', data.fileVoice[0]);

      const json = JSON.stringify({ CompanyId: company.companyId, ChannelId: CHANELLIST.VOICE.key});
      const blob = new Blob([json], {
        type: 'application/json'
      });

      formData.append("data", blob);

      voiceResponse = await updateRates(formData);
      if (!voiceResponse.isSuccessful) {
        messages.push(`VOICE: ${voiceResponse.error}`);
      }
    }

    return {
      error: messages.join('<br />')
    };
  }

  const onViewRates = (channelId) => {
    setShowUpdateCompanyRates(false);
    navigate(`/CompanyRates/company/${company.companyId}/channel/${channelId}`);
  }

  return (
    <CardCustom
      minWidth="250px"
      width="100%"
      extra={
        <Switch
          checked={enableUser}
          onClick={onClickUserSettings}
        />
      }
    >
      <Row>
        <Col span={24}>
          <div className={styles['avatar']}>
            <Avatar
              style={{ backgroundColor: enableUser ? orangeColor : grayColorOpacity }}
              shape='circle'
              size={60}
              icon={<FontAwesomeIcon icon={ faPlanetRinged }/>}
            />
            <p className='yp-title'>{ enableUser ? t("card-company-details.label-enabled") : t("card-company-details.label-disbled") }</p>
          </div>
          <p className={`yp-subtitle ${styles['title']}`}>{company.companyName}</p>
          <p className={styles['content']}><strong>{t("card-company-details.label-currency")}: </strong>{company.currency}</p>
          <p className={styles['content']}><strong>{t("card-company-details.label-time-zone")}: </strong>{company.timeZone}</p>
          <p className={styles['content']}><strong>{t("card-company-details.label-rates")}: </strong><a id={`change_rates__${company.companyName}`} className={styles['rates-link']} onClick={() => setShowUpdateCompanyRates(true)}>Change Rates</a></p>
          <div className={styles['content-balance']}>
            <p className={styles['content']}><strong>{t("card-company-details.label-balance")}: </strong>{currencyFormat(company.balance)}</p> 
            <span id={`add_balance_buttom_${company.companyName}`} className={styles['content-button']} onClick={() => drawerBalanceVisible()}><FontAwesomeIcon style={{ fontSize: "12px"}} icon={faPlus} /></span>
          </div>
          <p className={styles['content']}><strong>{t("card-company-details.label-contact-mail")}: </strong>{company.contactEmail}</p>
        </Col>
      </Row>
      <Drawer
       width={420}
        open={showBalanceDrawer}       
        styles={{ header: { backgroundColor: getGradientColor(orangeColor)} }}
        title="Asignacion de balance"
        onClose={() => {
          form.resetFields();
          setShowBalanceDrawer(false);
        }}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button
              type="secondary"
              data-name="btnCancel"
              onClick={() => {
                form.resetFields();
                setShowBalanceDrawer(false);
              }}
            >
              Cancel
            </Button>
            <Popconfirm title="Are you sure that yo want add blanace"
              okButtonProps={
                {
                  "data-testid": "okButtonConfirm" //Important to do test
                }
              }
              onConfirm={() => {
                form
                .validateFields()
                .then(values => {
                  
                  let data = {
                    ValueAmount: values.balance,
                    User: auth.userName,
                    CompanyId: company.companyId
                  }      

                  form.resetFields();
                  setShowBalanceDrawer(false);
                  onAddCompanyBalance(data);
                })
                .catch((info) => {
                });
              }}
              okText="yes"
              cancelText="no"
            >
              <Button
                id="aceptar_buttom_asig_balance"
                type="primary"
                data-name="btnSave">
                Save
              </Button>
            </Popconfirm>
          </div>
        }
      >
        <Form
          data-testid="drawer-content"
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            initialState: false,
          }}
        >
          <div className={styles['drawer-title']}><h2>{company.companyName}</h2></div>
          <Form.Item
            name="balance"
            label="Add balance"
            rules={[
              {
                required: true,
                message: 'Please input the balance!'
              },
              () => ({
                validator(_, value) {
                  if (value === 0) {
                    return Promise.reject("The balance must be greater than zero");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber style={{width: "100%"}} min={0} />
          </Form.Item>
        </Form>
        <div className={styles['drawer-balance-details']}>
          <p className={styles['drawer-balance-details-title']}>Curruent balance</p>
          <p className={styles['drawer-balance-details-value']}><span><FontAwesomeIcon icon={faWallet} /></span> {currencyFormat(balance?.availableBalance)} USD</p>
        </div>
        <div className={styles['drawer-balance-details']}>
          <p className={styles['drawer-balance-details-title']}>Balance on Hold</p>
          <p className={styles['drawer-balance-details-value']}><span><FontAwesomeIcon icon={faCreditCard} /></span> {currencyFormat(balance?.onHoldBalance)} USD</p>
        </div>
      </Drawer>
      <UpdateCompanyRates
        open={showUpdateCompanyRates}
        onClose={() => setShowUpdateCompanyRates(false)}
        onUpdate={onUpdateCompanyRates}
        onViewRates={onViewRates}
      />
    </CardCustom>
  );
}

export default CompanyCard;