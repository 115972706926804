import styles from './Button.module.css';
import propTypes from 'prop-types'

const buttonTypes = {
  primary: "yp-button-primary",
  secondary: "yp-button-secondary",
  optional: "yp-button-optional",
  link: "yp-button-link"
}

const Button = ({children, type, onClick, style, icon, disabled, dataName, id}) => {
  return (
    <button
      id={id}
      disabled={disabled}
      onClick={onClick}
      style={style}
      className={`${styles['yp-button']} ${styles[buttonTypes[type]]}`}
      data-name={dataName}
    >
      { icon && (<span className={`${styles['yp-button-icon']}`}>{icon}</span>)}
      <span>{children}</span>
    </button>
  );
}

Button.propTypes = {
  type: propTypes.string,
  onClick: propTypes.func,
  style: propTypes.object,
  icon: propTypes.object,
  disabled: propTypes.bool
}

export default Button;