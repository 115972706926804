import { useState, useEffect, useContext } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./TotalSms.module.css";
import { Spin } from "antd";
import { Column } from "@ant-design/charts";
import DataApi from "../../../../Api/DataApi";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST, CONSTANTUSERTYPE } from "../../../../utils/const";
import { AuthContext } from "../../../../contexts/authRedirect";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../../../utils/helpers";
import { getGradientColor, purpleColor, magentaColor, magentaColorOpacity } from "../../../../utils/colorsCss";

const TotalSms = ({filter}) => {
  const [t] = useTranslation("home-company");
  const { getConsumption } = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(200);

  useEffect(() => {
    setLoading(true);
    getTotalSmsData()
      .then(response => {
        setLoading(false);
        const { chartData, totalValue } = response;

        if (Array.isArray(chartData)) {
          setData(chartData);
        } else {
          console.error('Datos de gráfico no son un array válido');
          setData([]);
        }
        
        setTotal(totalValue);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [filter]);

  const getTotalSmsData = async () => {
    let data = {
      companyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      accountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null,
    };

    let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? CONSTANTUSERTYPE.COMPANY.key : CONSTANTUSERTYPE.ACCOUNT.key;
    let response = await getConsumption(CHANELLIST.SMS.value, userType, filter.time, data);

    let responseData = [];
    response.items.forEach(x => {
      let time = x.label;
      x.itemsMode.forEach(y => {
        responseData.push({ type: y.mode, value: y.countMessageMode, time });
      });
    });

    let totalValue = response.totalMessages;
    return {chartData: responseData, totalValue: totalValue}; 
  }

  const annotations = [];
  // Group by 'time' and then iterate over the groups to create annotations
  const groupedData = data.reduce((acc, currentItem) => {
    // Group the data by the 'time' field
    (acc[currentItem.time] = acc[currentItem.time] || []).push(currentItem);
    return acc;
  }, {});

  Object.keys(groupedData).forEach((k) => {
    const values = groupedData[k];
    //const value = values.reduce((a, b) => a + b.value, 0); // If you need to sum the values.
    annotations.push({
      type: "text",
      //position: [k, value], // If you need to position the annotation with the calculated value.
      //content: `${value}`, // If you need to display the calculated value.
      style: {
        textAlign: "center",
        fontSize: 14,
        fill: "rgba(0,0,0,0.85)",
      },
      offsetY: -10,
      xField: "time",
      yField: "value",
    });
  });

  const config = {
    data,
    xField: 'time',
    yField: 'value',
    seriesField: 'type',
    isStack: true,
    color: [magentaColor, magentaColorOpacity],
    colorField: 'type', 
    legend: {
      position: "top",
      flipPage: false
    },
    scale: {
      color: {
        range: [magentaColor, magentaColorOpacity],
      }
    },
    annotations, 
  };

  return (
    <div className={styles['dashboard-body-card-single']}>
      <CardCustom
        style={{ height: "100%", width: "100%" }}
        title={
          <div className={styles['card-title']}>
            <p>{t("card-total-sms.label-title")}</p>
            <HelperButton
              message={t("card-total-sms.tooltip-title")}
              color={getGradientColor(purpleColor)}
            />
          </div>
        }
      >
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              <p>{numberFormat(total, 0)} <span>{t("card-total-sms.label-subtitle")}</span></p>
              <Column height={300} {...config} />
            </div>
          </Spin>
        </div>
      </CardCustom>
    </div>
  );
};

export default TotalSms;
