import { Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import HelperButton from "../../HelperButton/HelperButton";
import styles from "./HeaderMap.module.css";

const { Option } = Select;

const HeaderMap = ({headers,
  defaultWildcardSelected,
  defaultPhoneFieldName,
  defaultHeaderSelected,
  onChangeMobileNumberField,
  onChangeCustomFields,
  onChangeHeaders}) => {

  const [t] = useTranslation("create-contacts");

  const [ defaultPhoneSelected, setDefaultPhoneSelected] = useState([]);
  const [ phone, setPhone] = useState(null);
  const [ phoneFieldName, setPhoneFieldName] = useState(defaultPhoneFieldName ? defaultPhoneFieldName : "");
  const [ wildCards, setWildCards] = useState([]);
  const [ wildCardsValue, setWildCardsValue] = useState(defaultWildcardSelected.length > 0 ? defaultWildcardSelected : []);

  useEffect(() => {
    let defaultPhone = headers.length > 0 && phoneFieldName != "" ? headers.find(h => h.name === phoneFieldName).name : null

    setDefaultPhoneSelected(defaultPhone);
    setPhone(defaultPhone);

    if(defaultHeaderSelected.length === 0) {
      setWildCards([]);
      setWildCardsValue([]);
    } else {
      let wildcardtFilter = headers.filter(h => h.name !== phoneFieldName);
      let wildCardMap = wildcardtFilter.map(x => { return { label: x.name, value: x.name } })
      setWildCards(wildCardMap);
    }
    
  }, [defaultHeaderSelected])

  const handlePhoneNumberColumn = (value) => {
    setPhoneFieldName(value);

    let temp = headers.find(h => h.name === value);
    onChangeHeaders([temp], true);

    let wildcardtFilter = headers.filter(h => h.name !== value);
    let wildCardMap = wildcardtFilter.map(x => { return { label: x.name, value: x.name } })
    setWildCards(wildCardMap);
    setWildCardsValue([]);
    
    onChangeMobileNumberField(value);
    onChangeCustomFields([]);
  }

  const handleWildCardsChange = (value) => {
    let newWildCards = value.map(x => { return { label: x, value: x } });
    setWildCardsValue(value);

    let newHeader = [];

    newWildCards.forEach(item => {
      let temp = headers.find(h => h.name === item.value);
      newHeader.push(temp);
    });

    onChangeHeaders(newHeader, false);
    onChangeCustomFields(value);
  };

  return (
    <>
      <div className={styles['phone']}>
        <div className={styles['phone-field-content']} id="phone_field_contact">
          <p>{t("map-header.phone-title")}</p>
          <div className={styles['phone-field']}>
            <Select
              style={{ width: "100%" }}
              placeholder={t("map-header.placeholder-phone")}
              onChange={handlePhoneNumberColumn}
              defaultValue={defaultPhoneSelected}
              value={phone}
              disabled={headers.length === 0}
            >
              {
                headers.map(item =>
                  <Option key={item.name} value={item.name}>{item.name}</Option>
                )
              }
            </Select>
            <HelperButton
              message={t("map-header.tooltip-phone")}
              color={getGradientColor(purpleColor)}
            />
          </div>        
        </div>
      </div>
      <div className={styles['wildcards']}>
        <div className={styles['wildcard-select']} id="wildcard_field_contact">
          <div className={styles['wildcard-title']}>
            <p>{t("map-header.wildcards-title")}</p>
            <HelperButton
              message={t("map-header.tooltip-wildcard")}
              color={getGradientColor(purpleColor)}
            />
          </div>
          
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder={t("map-header.placeholder-wildcards")}
            defaultValue={defaultWildcardSelected}
            onChange={handleWildCardsChange}
            options={wildCards}
            disabled={wildCards.length === 0}
            value={wildCardsValue}
          />  
        </div>
      </div>
    </>
  );
}
 
export default HeaderMap;