import React, { useState, useEffect } from 'react';
import { Alert, Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import ShortlinkApi from '../../../Api/ShortlinkApi';
import { getGradientColor, purpleColor } from '../../../utils/colorsCss';
import CONSTANTS from '../../../utils/const';
import encodingSevenBits, { notificationError, notificationWarning } from '../../../utils/helpers';
import HelperButton from '../../HelperButton/HelperButton';
import MessageWildcard from '../../MessageWildcard/MessageWildcard';
import styles from './ContentMessage.module.css';
import Button from '../../Button/Button';
import ModalConfirmation from '../../ModalConfirmation/ModalConfirmation';

const ContentMessage = ({
  customFields,
  defaultSenderId,
  defaultMessage,
  defaultWildCards,
  defaultUrl,
  onChangeSender,
  onChangeMessage,
  onChangeWildCards,
  onChangeUrl,
  onChangeShortLink,
  onSaveTemplate,
}) => {
  const [form] = Form.useForm();
  const [t] = useTranslation('shortlink-campaign');

  const [senderId, setSenderId] = useState(defaultSenderId.length > 0 ? defaultSenderId : '');
  const [url, setUrl] = useState(defaultUrl.length > 0 ? defaultUrl : '');
  const [hasMessage, setHasMessage] = useState(false);
  const [isBasicEncoding, setIsBasicEncoding] = useState(true);
  const [specialCharacters, setSpecialCharacters] = useState([]);
  const [urlChecked, setUrlChecked] = useState('');
  const [modalTemplateVisible, setModalTemplateVisible] = useState(false);
  const [showTemplateNameValidation, setShowTemplateNameValidation] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [urlError, setUrlError] = useState(false);
  const [hasShortlink, setHasShortlink] = useState(false); // Nuevo estado para verificar shortlink
  const [hasShortlinkWildcardSelected, setHasShortlinkWildcardSelected] = useState(false); // Nuevo estado para verificar la selección del shortlink wildcard

  const { generateShortlink } = ShortlinkApi();

  useEffect(() => {
    if (defaultMessage.message) {
      onChangeWildcardMessage(defaultMessage);
    }
  }, [defaultMessage]);

  const handleSenderIdChange = (e) => {  
    const value = e.target.value;
    if (value.length <= 12) {
      setSenderId(value);
      onChangeSender(value);
    }
  };

  const handleUrlChange = (e) => {
    if (!e.target.value.includes('https://')) {
      setUrl(e.target.value);
      onChangeUrl(e.target.value);
    } else {
      notificationWarning(t('content-message.notification-error-shortlink-https'));
      setUrl('');
      onChangeUrl('');
    }
  };

  const onChangeWildcardMessage = (message) => {
    let ms = message.message.replaceAll(CONSTANTS.WILDCARD, '');
    onChangeMessage(message);
    setHasMessage(message.message.length > 0);

    // Verificar si el mensaje contiene el wildcard del shortlink
    const hasShortlink = message.message.includes(CONSTANTS.WILDCARD);
    setHasShortlink(hasShortlink);

    // Verificar si un wildcard seleccionado es de tipo shortlink
    const messageArea = document.getElementById('message-area');
    if (messageArea) {
      const wildcards = messageArea.querySelectorAll('[class*="MessageWildcard_message-area-wildcard"]');
      let foundShortlink = false;
      for (let wildcard of wildcards) {
        if (wildcard.className.includes('shortlink')) {
          foundShortlink = true;
          break;
        }
      }
      setHasShortlinkWildcardSelected(foundShortlink);
    }
  };

  const onBlurUrl = () => {
    if (url === '') {
      setUrlError(true);
      return;
    }
    let value = `https://${url}`;
    onChangeUrl(value);
    if (urlChecked !== value && value !== '') {
      generateShortlink(value)
        .then((response) => {
          setUrlChecked(value);
          onChangeShortLink(response);
        })
        .catch((error) => {
          notificationError(t('content-message.notification-error-url'));
          console.error(error);
        });
    }
  };

  const handleOkTemplate = () => {
    if (!templateName) {
      setShowTemplateNameValidation(true);
      return;
    }

    handleCancelTemplate();
    onSaveTemplate(templateName);
  };

  const handleCancelTemplate = () => {
    setModalTemplateVisible(false);
    setShowTemplateNameValidation(false);
    setTemplateName('');
    form.resetFields();
  };

  const handleTemplateNameChange = (e) => {
    let value = e.target.value;
    if (value) {
      setShowTemplateNameValidation(false);
      setTemplateName(value);
    }
  };

  const validateMessageWildcard = () => {
    //Validar la presencia del shortlink en el mensaje
    return hasShortlink;
  };

  return (
    <>
      <div className={styles['message-form']}>
        <div>
          <Form layout="vertical">
            <Form.Item label={t('content-message.label-sender-id')}>
              <Row>
                <Col>
                  <Input
                    style={{ width: 500 }}
                    placeholder="Sender Id"
                    value={senderId}
                    onChange={handleSenderIdChange}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
        <div className={styles['message-sender-tooltip']}>
          <HelperButton message={t('content-message.tooltip-sender-id')} color={getGradientColor(purpleColor)} />
        </div>
      </div>
      <div className={styles['message-form']}>
        <div>
          <Form layout="vertical">
            <Form.Item label={t('content-message.label-url')}>
              <Row>
                <Col>
                  <Input
                    disabled={senderId === ''}
                    style={{ width: 500 }}
                    addonBefore="https://"
                    placeholder="Url"
                    value={url}
                    onChange={handleUrlChange}
                    onBlur={onBlurUrl}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
        <div className={styles['message-sender-tooltip']}>
          <HelperButton message={t('content-message.tooltip-url')} color={getGradientColor(purpleColor)} />
        </div>
      </div>
      <div className={styles['message-form']}>
        <div>
          <Form layout="vertical">
            <Form.Item label={t('content-message.label-message')}>
              <Row>
                <Col>
                  <MessageWildcard
                    disabled={url === ''}
                    contentEditable={true}
                    wildcardsFields={customFields}
                    defaultMessage={defaultMessage}
                    defaultWildCards={defaultWildCards}
                    onChangeMessage={onChangeWildcardMessage}
                    onChangeWildCards={onChangeWildCards}
                    validate={validateMessageWildcard} // Nueva validación para MessageWildcard
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
        <div className={styles['message-sender-tooltip']}>
          <HelperButton message={t('content-message.tooltip-url')} color={getGradientColor(purpleColor)} />
        </div>
      </div>
      <div className={styles['save-template']}>
        <Button
          type="primary"
          onClick={() => setModalTemplateVisible(true)}
          disabled={senderId.length === 0 || url.length === 0 || !hasMessage || !hasShortlinkWildcardSelected || urlError}
        >
          {t('content-message.button-save-template')}
        </Button>
      </div>
      <ModalConfirmation
        open={modalTemplateVisible}
        title={t('content-message.modal-template-title')}
        onCancel={handleCancelTemplate}
        onOk={handleOkTemplate}
        okBtnText={t('content-message.modal-template-btn-ok')}
      >
        <Form data-testid="content" name="validate_other" form={form} layout="vertical">
          <Row>
            <Col>
              <p>{t('content-message.modal-template-content')}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="TemplateName"
                label={t('content-message.modal-template-label-name')}
                extra={showTemplateNameValidation && (
                  <Alert message={t('content-message.modal-template-required-field')} type="error" showIcon />
                )}
                validateStatus={showTemplateNameValidation ? 'error' : ''}
              >
                <Input
                  id="TemplateName"
                  name="TemplateName"
                  style={{ width: 320 }}
                  placeholder={t('content-message.modal-template-placeholder-name')}
                  onChange={handleTemplateNameChange}
                  value={templateName}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalConfirmation>
    </>
  );
};

export default ContentMessage;
