import { useContext, useState } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./TotalConversionRate.module.css";
import { useEffect } from "react";
import { Spin } from "antd";
import { Column } from "@ant-design/charts";
import { each, groupBy } from '@antv/util';
import { getGradientColor, purpleColor, orangeColor } from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../../../utils/helpers";
import DataApi from "../../../../Api/DataApi";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST, CONSTANTUSERTYPE } from "../../../../utils/const";
import { AuthContext } from "../../../../contexts/authRedirect";

const TotalConversionRate = ({filter}) => {

  const [t] = useTranslation("home-company");
  const {getConsumption} = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(200);

  useEffect(() => {
    setLoading(true);

    getTotalConversionRateData()
    .then(response => {
      setLoading(false);

      const {chartData, totalValue} = response;

      setData(chartData);
      setTotal(totalValue);
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    })
    
  }, [filter])

  const getTotalConversionRateData = async () => {
    let data = {
      companyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      accountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }

    let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? CONSTANTUSERTYPE.COMPANY.key : CONSTANTUSERTYPE.ACCOUNT.key;
    let response = await getConsumption(CHANELLIST.SHORTLINK.value, userType, filter.time, data);

    let responseData = [];
    response.items.forEach(x => {
      let time = x.label;
      x.itemsMode.forEach(y => {
        responseData.push({type: y.mode, value: y.countMessageMode, time: time})
      });
    });

    let totalValue = response.totalMessages;
    return {chartData: responseData, totalValue: totalValue}; 
  }

  const annotations = [];
  each(groupBy(data, 'time'), (values, k) => {
    //const value = values.reduce((a, b) => a + b.value, 0);
    annotations.push({
      type: 'text',
      //position: [k, value],
      //content: `${value}`,
      style: {
        textAlign: 'center',
        fontSize: 14,
        fill: 'rgba(0,0,0,0.85)',
      },
      offsetY: -10,
      xField: 'time',
      yField: 'value',
    });
  });

  const config = {
    data,
    xField: 'time',
    yField: 'value',
    seriesField: 'type',
    isStack: true,
    color: [purpleColor, orangeColor],
    colorField: 'type', 
    scale: {
      color: {
        range: [purpleColor, orangeColor],
      }
    },
    legend: {
      position: "top",
      flipPage: false
    },
    annotations
  };

  return (
    <div className={styles['dashboard-body-card-single']}>
      <CardCustom
        style={{height: "100%", widt:"100%"}}
        title={
        <div className={styles['card-title']}>
          <p>{t("card-total-convertion-rate.label-title")}</p>
          <HelperButton
            message={t("card-total-convertion-rate.tooltip-title")}
            color={getGradientColor(purpleColor)}
          />
        </div>
        }
      >
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              <p>{numberFormat(total, 0)} <span>{t("card-total-convertion-rate.label-subtitle")}</span></p>
              <Column height={300} {...config} />
            </div>            
          </Spin>
        </div>
      </CardCustom>
    </div>
  );
}
 
export default TotalConversionRate;