import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RatesApi from "../../Api/RatesApi";
import Rates from "../../components/CompanyRates/Rates/Rates";
import useAuth from "../../Hooks/useAuth";
import useSpinner from "../../Hooks/useSpinner";
import TYPES from "../../reducers/types";
import CONSTANTS, { CHANELLIST } from "../../utils/const";
import styles from "./CompanyRates.module.css";

const CompanyRates = ({match}) => {
  const { companyId, channelId } = useParams();

  const { getRatesByCompany } = RatesApi();

  const [rates, setRates] = useState([]);
  const [datFilters, setDataFilters] = useState({companyId: null, channelId: null});

  const { auth } =  useAuth();
  const { spinnerDispacth } = useSpinner();

  useEffect(() => {

    const filters = {
      companyId: companyId || auth.companyId,
      channelId: channelId || CHANELLIST.SMS.key,
    };

    /*let filters = {
      companyId: match.params.companyId ? match.params.companyId : auth.companyId,
      channelId: match.params.channelId ? match.params.channelId : CHANELLIST.SMS.key
    };*/

    setDataFilters(filters);

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.COMPANYRATES }
    });

    dataProccess(filters.companyId, filters.channelId)
    .then(response => {
      var ratesMap = response.rates.map((r) => {
        return {
          country: r.country,
          network: r.network,
          mccMnc: r.mccMnc,
          rate: r.rate,
          effectiveDate: r.effectiveDate,
          isStandard: r.isStandard
        };
      });

      setRates(ratesMap);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.COMPANYRATES }
      });
    })
    .catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.COMPANYRATES }
      });
    })
  }
  , [companyId, channelId, auth.companyId, spinnerDispacth]);

  const dataProccess = async (companyId, channelId) => {
    let ratesResponse = await getRatesByCompany(companyId, channelId);
    return { rates: ratesResponse };
  }

  const onChangeChannel = (channelId) => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.COMPANYRATES }
    });

    setDataFilters({...datFilters, channelId: channelId})

    dataProccess(datFilters.companyId, channelId)
    .then(response => {
      var ratesMap = response.rates.map((r) => {
        return {
          country: r.country,
          network: r.network,
          mccMnc: r.mccMnc,
          rate: r.rate,
          effectiveDate: r.effectiveDate,
          isStandard: r.isStandard
        };
      });

      setRates(ratesMap);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.COMPANYRATES }
      });
    })
    .catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.COMPANYRATES }
      });
    })
  }

  return (
    <div className={styles['rates-container']}>
      <Rates 
        onChangeChannel={onChangeChannel}
        channelId={datFilters.channelId}
        data={rates}
      />
    </div>
  );
}
 
export default CompanyRates;