import { Input, Pagination } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ListTemplates.module.css";
import { useEffect } from "react";
import CONSTANTS from "../../utils/const";
import TableCardCustom from "../TableCardCustom/TableCardCustom";
import TableCardHeadCustom from "../TableCardCustom/TableCardHeadCustom/TableCardHeadCustom";
import TableCardBodyCustom from "../TableCardCustom/TableCardBodyCustom/TableCardBodyCustom";
import TableCardBodyTrCustom from "../TableCardCustom/TableCardBodyTrCustom/TableCardBodyTrCustom";
import TableCardButtons from "../TableCardCustom/TableCardButtons/TableCardButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye } from "@fortawesome/pro-solid-svg-icons";
import ModalConfirmation from "../ModalConfirmation/ModalConfirmation";
import { dateFormat } from "../../utils/helpers";
import { Link } from "react-router-dom";
import { Button, Popover } from 'antd';
import CampaignApi from '../../Api/CampaignApi';
import { v4 as uuidv4 } from "uuid";

const ListTemplates = ({templates, onDeleteTemplate, data, campaignId}) => {

  const [t] = useTranslation("templates-history");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(CONSTANTS.PAGINATION);
  const [totalPage, setTotalPage] = useState(0);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [modalMessage, setModalMessage] = useState("");
  const [modalContactsVisible, setModalContacsVisible] = useState(false);
  const [templateDelete, setTemplateDelete] = useState(null);
  const [popoverVisible, setPopoverVisible] = useState([]);
  const [ showSpinner, setShowSpinner ] = useState(false);
  const [ dataFile, setDataFile ] = useState(null);

  const rebuildHtmlMessage = (message) => {
    let messageCopy = message;
    const regex = /\@#(.*?)\@#/g;
    const matched = messageCopy.match(regex);
  
    if (matched) {
      matched.forEach(match => {
        const value = match.replace(/\@#/g, '');
        const newValue = getHtmlWildCard(value);       
        messageCopy = messageCopy.replace(match, `<span class="wildcard-message">${newValue}</span>`);
      });
    }
    
    return messageCopy;
  }
  
  const getHtmlWildCard = (value) => {
    return value;
  }

  const handlerShowFileDetails = (index) => {
    if (popoverVisible === index) {
      setPopoverVisible(null);
    } else {
      setPopoverVisible(index);
    }
  };
 
  useEffect(() => {
    setTotalPage(templates.length);
    setDataFilteredByPage(templates, 1);
  }, [templates]);

  const onChangePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    setDataFilteredByPage(templates, pageNumber);
  }

  const setDataFilteredByPage = (items, page) => {
    const index = (page - 1) * itemsPerPage;
    const currentData = items.slice(index, index + itemsPerPage);
    setDataFiltered(currentData);
  }
  
  const onSearch = (e) => {
    let value = e.target.value;
    let result = templates.filter(x => x.templateName.toLowerCase().includes(value.toLowerCase()));
    setDataFiltered(result);
    setTotalPage(result.length);
  }

  const onDeleteClick = (template) => {
    
    let message = t("template-information.modal-delete-template-content");
    message = message.replaceAll("[name]", `"${template.templateName}"`);
    setTemplateDelete(template);
    setModalMessage(message);
    setModalContacsVisible(true);
  }

  const handleConfirmModal = () => {
    
    setModalContacsVisible(false);
    onDeleteTemplate(templateDelete.templateId);
    setCurrentPage(1);
  }
  


  const getChannelName = (channelId) => {
    var result = CONSTANTS.LIST.CHANELS.find(x => x.key === channelId);
    return result?.value;
  }


 
  const urls = {
    voz: "/Campaigns/Voz",
    sms: "/Campaigns/BulkCampaign",
    shortlink: "/Campaigns/ShortLinks",
  };

  

  return (
    <div className={styles['content']}>
      <div className={styles['content-title']}>
        <p>{t("list-templates.label-title")}</p>
        <div>
          <Input placeholder={t("list-templates.input-placeholder-search")} onChange={onSearch} style={{width:300}}/>
        </div>
        <Pagination
          defaultCurrent={currentPage}
          current={currentPage}
          onChange={(pageNumber) => onChangePagination(pageNumber)}
          total={totalPage}
          pageSize={itemsPerPage}
          showSizeChanger={false}
        />
      </div>
      <div className={styles['content-grid']}>
        {
          dataFiltered.length > 0 && (
            <TableCardCustom>
              <TableCardHeadCustom>
                <th>
                </th>  
                {/* <th>{t("template-information.label-edit")}</th>               */}
                <th>{t("template-information.label-name")}</th>
                <th>{t("template-information.label-creation-date")}</th>
                <th>{t("template-information.label-chanel")}</th> 
                <th>{t("template-information.label-account")}</th>  
                <th>{t("template-information.label-associated-contacts")}</th>
                <th>{t("template-information.label-see-message")}</th>
              </TableCardHeadCustom>
              <TableCardBodyCustom>
              {
                dataFiltered.map((item, index) => {
                  const channelText = getChannelName(item.channelId).toLowerCase(); 
                  let editUrl;
                  
                  if (channelText === "voice") {
                    editUrl = urls.voz;
                  } else if (channelText === "shortlink") {
                    editUrl = urls.shortlink;
                  } else {
                    editUrl = urls.sms;
                  }
                  return (
                  <TableCardBodyTrCustom key={`${item.id}-${index}`}>
                    <td>
                      <TableCardButtons>
                        <a onClick={() => onDeleteClick(item)}><FontAwesomeIcon icon={ faTrash } id={`trash-icon-${index}`}/></a>
                      </TableCardButtons>
                    </td>
                    {/* <td>
                    <Link to={editUrl} className={styles['dashboard-button']}>{t("template-information.label-edit")}</Link>
                    </td> */}
                    <td className={styles['name-table-history']}>
                      {item.templateName}
                    </td>
                    <td>
                      {dateFormat(item.creationDate, true)}
                    </td>
                    <td>
                      {getChannelName(item.channelId)}
                    </td>
                    <td>
                      {item.accountName}
                    </td>
                    <td>
                      {item.contacts}
                    </td>
                    <td>
                      <Popover
                        placement="bottom"
                        content={
                          <table className={styles['table-history']}>
                            <thead>
                              <tr className={styles['table-history-header']}>                               
                                <th>{t("template-information.label-title-message")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className={styles['table-history-body']}>
                                  <td dangerouslySetInnerHTML={{ __html: rebuildHtmlMessage(item.channelParams.MessageBase) }} />
                              </tr>                             
                            </tbody>
                          </table>
                        }
                        trigger="click"
                        open={popoverVisible === index}
                        onOpenChange={(visible) => {
                          if (!visible) setPopoverVisible(null);
                        }}
                      >
                        <Button
                          icon={<FontAwesomeIcon icon={ faEye } id={`eye-icon-${index}`}/>}
                          onClick={() => handlerShowFileDetails(index)}
                          loading={showSpinner}
                          className={styles['table-history-button']}
                        >
                        </Button>
                      </Popover>                     
                    </td>
                  </TableCardBodyTrCustom>            
                );
              })
            }
              </TableCardBodyCustom>
            </TableCardCustom>
          )
        }
      </div>
      <ModalConfirmation
        open={modalContactsVisible}
        title={t("template-information.modal-delete-template-title")}
        onCancel={() => setModalContacsVisible(false)}
        onOk={handleConfirmModal}
        okBtnText={t("template-information.modal-delete-template-btn-ok")}
      >
        <p>{modalMessage}</p>
      </ModalConfirmation>
    </div>
  );
}
 
export default ListTemplates;