import React, { useContext, useEffect, useState } from "react";
import { Alert, Drawer, Input, Form, Button, Popconfirm, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { orangeColor, getGradientColor } from "../../../utils/colorsCss";
import useFacebookLogin from "../../../Hooks/useFacebookLogin";
import { cleanErrorMessage, notificationOk } from '../../../utils/helpers';
import WhatsappApi from "../../../Api/WhatsappApi";
import { notificationError } from "../../../utils/helpers";
import { AuthContext } from "../../../contexts/authRedirect";
import "./WhatsAppBusinessChannel.css"
import useSpinner from "../../../Hooks/useSpinner";
import TYPES from "../../../reducers/types";
import CONSTANTS, { embedded_sign_up_flow_url } from "../../../utils/const";

const buttonFCStyle = {
  backgroundColor: '#1877f2',
  border: '0',
  borderRadius: '4px',
  borderColor: "unset",
  color: '#fff',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: 'bold',
  height: '40px',
  padding: '0 24px',
  marginBottom: '24px',
};

const buttonFCStyleDisabled = {
  backgroundColor: '#f5f5f5',
  border: '0',
  borderRadius: '4px',
  //borderColor: "#d9d9d9",
  color: '#d9d9d9',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: 'bold',
  height: '40px',
  padding: '0 24px',
  marginBottom: '24px',
};

const widthEn = {
  width: "196px"
}

const widthEs = {
  width: "244px"
}

const CreateAccountDrawer = ({ visible, onClose, setShouldRefetch }) => {
  const [t] = useTranslation("whatsapp-channel");
  const { i18n } = useTranslation("whatsapp-channel");
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { user, buttonEnabled, handleFacebookLogin, handleFacebookLogout } = useFacebookLogin();
  const { createUpdateWaba, getWabaAccounts, getWabaDetails } = WhatsappApi();
  const { auth } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [accountPhones, setAccountPhones] = useState("")
  const [accountPhonesNames, setAccountPhonesNames] = useState([])
  const [ypAccounts, setYPAccounts] = useState([])
  const [initialYpAccounts, setInitialYPAccounts] = useState([])
  const [fbAccounts, setFbAccounts] = useState([])
  const [fbAccountsNames, setFbAccountsNames] = useState([])
  const [wabaAccounts, setWabaAccounts] = useState([])
  const [name, setName] = useState("")
  const { spinnerDispacth } = useSpinner();

  // Loading Process, when we dont have a value for user (usercode or error during Facebook login) 
  useEffect(() => {
    if (!user) {
      setIsLoading(true)
      getWabaAccounts(auth.companyId)
        .then(accounts => {
          setWabaAccounts(accounts.data);
          setInitialYPAccounts((accounts?.data || []).filter(v => !v.wabaPhoneId));
          setIsLoading(false)
        })
        .catch((e) => {
          notificationError(cleanErrorMessage(e) || t("error-user-cancelled-login"), 30);
          onClose();
          handleFacebookLogout()
          setIsLoading(false)
        })
    }
  }, [user]);

  // Facebook Login process: user contains the user code or error flag during Facebook login. 
  useEffect(() => {
    if (user !== null && user !== "_error_") {
      setIsLoading(true)

      getWabaDetails(user)
        .then(v => {
          const data = JSON.parse(v)
          let _fbAccounts = data?.data?.map(v => ({ value: v.name, label: v.name }))
          if (_fbAccounts.length === 1) {
            form.setFieldsValue({ name: _fbAccounts[0].value })
            setName(_fbAccounts[0].value)
          }
          setFbAccountsNames(_fbAccounts)
          setFbAccounts(data.data)
          setIsLoading(false)
        })
        .catch((e) => {
          notificationError(cleanErrorMessage(e) ||  t("error-user-cancelled-login"), 30)
          onClose();
          handleFacebookLogout()
          setIsLoading(false)
        })
    }
    else if (user === "_error_") {
      notificationError(t("error-user-cancelled-login"), 30)
      onClose();
      handleFacebookLogout()
      setIsLoading(false)
    }
  }, [user]);

  useEffect(() => {
    if (user !== null && user !== "_error_") {
      const _selectedAccountByChangingName = fbAccounts.find(a => a.name === name)

      if (_selectedAccountByChangingName !== null && _selectedAccountByChangingName !== undefined) {
        let phones = (_selectedAccountByChangingName?.phones || []).map(p => ({ value: p.display_phone_number, label: p.display_phone_number, name_status: p.name_status, name: p.verified_name, }))
        let names = (_selectedAccountByChangingName?.phones || []).map(p => ({ value: p.verified_name + "@__@__@" + p.display_phone_number, label: p.verified_name + " " + p.display_phone_number, name_status: p.name_status }))
        setAccountPhones(phones)
        setAccountPhonesNames(names)
        if (phones.length === 1) {
          form.setFieldsValue({
            phone: phones[0].value,
            wabaPhoneId: _selectedAccountByChangingName.phones[0]?.id,
            wabaId: _selectedAccountByChangingName.wabaId,
            name_status: _selectedAccountByChangingName.phones[0]?.name_status
          })
        }
      } else {
        let phones = []
        let _phonesNames = []
        fbAccounts.forEach(item => {
          (item?.phones || []).forEach(phone => {
            phones.push({
              value: phone.display_phone_number,
              label: phone.display_phone_number,
              name_status: phone.name_status,
              name: phone.verified_name,
              
            });
            _phonesNames.push({
              value: phone.verified_name + "@__@__@" + phone.display_phone_number,
              label: phone.verified_name + " " + phone.display_phone_number,
              name_status: phone.verified_name,
            })
          });
        });
        setAccountPhones(phones)
        setAccountPhonesNames(_phonesNames)
      }
      const filtered = wabaAccounts
        .map(account => ({ label: account.accountName, value: account.accountId, disabled: account.wabaId !== null && account.wabaPhoneId !== null && account.status !== "DECLINED" }));
      setYPAccounts(filtered);
    }
  }, [fbAccounts, wabaAccounts, name, user]);

  useEffect(() => {
    form.setFieldsValue({
      phone: null,
      name: null,
      wabaPhoneId: null,
      wabaId: null,
      name_status: null
    })
  }, [name]);

  const _handleFacebookLogout = () => {
    form.resetFields()
    handleFacebookLogout()
  }

  const handleDownload = () => {
    const fileUrl = embedded_sign_up_flow_url
    window.open(fileUrl, '_blank');
  };


  return (
    <Drawer
      open={visible}
      title={t("title")}      
      width={360}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      footer={
        <div
          style={!user || isCreating || isLoading ? {
            pointerEvents: user !== null ? 'auto' : 'none',
            opacity: user !== null ? 1 : 0.5,
            textAlign: "center",
          } : {
            textAlign: "center",
          }}
        >
          <Button
            id="create-account-cancel-button"
            onClick={() => {
              form.resetFields();
              onClose();
            }}
            style={{ marginRight: 50 }}
            disabled={isLoading || isCreating}
          >
            {t("button-cancel")}
          </Button>
          <Popconfirm
            title={t("pop-confirm-title")}
            okButtonProps={{
              "data-testid": "okButtonConfirm", //Important to do test
              id: "create-account-popconfirm-yes-button"
            }}
            cancelButtonProps={{
              id: "create-account-popconfirm-no-button"
            }}
            disabled={isCreating}
            onConfirm={() => {
              setIsCreating(true)

              form
                .validateFields()
                .then((values) => {
                  const _selectedAccountByChangingName = fbAccounts.find(a => a.name === name)
                  const wabaId = fbAccounts.find(a => a.name === name).wabaId
                  const wabaPhoneId = _selectedAccountByChangingName?.phones.find(p => p.display_phone_number === values.phone)?.id

                  spinnerDispacth({
                    type: TYPES.SPINNER.SHOW,
                    payload: { show: true, item: CONSTANTS.SPINNERITEM.CREATEWABA }
                  });
                  values.name = values.name.split("@__@__@")[0]
                  createUpdateWaba({
                    ...values,
                    companyId: auth.companyId,
                    lastModifiedBy: auth.userName,
                    status: accountPhones.find(v => v.value === values.phone)?.name_status,
                    wabaId,
                    wabaPhoneId,
                  }, "POST")
                    .then((v) => {
                      notificationOk(t("notification-ok"));
                      setShouldRefetch(true)
                      form.resetFields();
                      onClose()
                      setIsCreating(false)
                      spinnerDispacth({
                        type: TYPES.SPINNER.SHOW,
                        payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATEWABA }
                      });
                    })
                    .catch(e => {
                      console.log("e", e)
                      notificationError(cleanErrorMessage(e) || t("error-creating-account"), 15)
                      setIsCreating(false)
                      spinnerDispacth({
                        type: TYPES.SPINNER.SHOW,
                        payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATEWABA }
                      });
                    })
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                  setIsCreating(false)
                  spinnerDispacth({
                    type: TYPES.SPINNER.SHOW,
                    payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATEWABA }
                  });
                });
            }}
            okText={t("yes")}
            cancelText={t("no")}
          >
            <Button type="primary" disabled={isLoading || isCreating} id="create-account-confirm-button">
              {t("button-confirm")}
            </Button>
          </Popconfirm>
        </div>
      }
    >
      {initialYpAccounts.length === 0 && (
        <Alert
          message={t("no-accounts-available")}
          type="warning"
          showIcon
          style={{ marginBottom: 20, backgroundColor: "#fffce6", border: "1px solid #ffe68f" }}
        />
      )}
      {!user && initialYpAccounts.length !== 0 && (
        <Alert
          className="facebook-login-alert"
          message={t("facebook-login-required-alert-message")}
          type="info"
          showIcon
          style={{ marginBottom: 20, backgroundColor: "#e6f5fe", border: "1px solid #92c9fb" }}
        />
      )}
      {!user ? 
        <Button
          label={t("label-name")}
          type="primary"
          style={(initialYpAccounts.length === 0 || !buttonEnabled) ?
            i18n.language === "en" ? { ...buttonFCStyleDisabled, ...widthEn } : { ...buttonFCStyleDisabled, ...widthEs } :
            i18n.language === "en" ? { ...buttonFCStyle, ...widthEn } : { ...buttonFCStyle, ...widthEs }}
          onClick={handleFacebookLogin}
          disabled={initialYpAccounts.length === 0 || !buttonEnabled}
          id="create-account-facebook-login-button"
        >
          {t("button-facebook-embedded-signup")}
        </Button> :
        <Button className="log-into-another-account" type="link" onClick={_handleFacebookLogout} id="create-account-facebook-login-another-account-button">
          {t("log-into-another-facebook-account")}
        </Button>
      }
      <div
        style={{
          pointerEvents: user !== null || isCreating ? 'auto' : 'none',
          opacity: user !== null || isCreating ? 1 : 0.5,
        }}
      >
        <Spin spinning={isLoading}>
          <Form
            data-testid="drawer-content"
            form={form}
            layout="vertical"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                name="_name"
                label={t("label-name-waba")}
                style={{ display: "flex", flexDirection: "column", width: "100%" }}
                placeholder={t("placeholder-choose-name")}
                rules={[
                  {
                    required: true,
                    message: t("rules-name-required"),
                  },
                ]}
              >
                <Select
                  id="create-account-wabas-input"
                  options={fbAccountsNames}
                  onChange={v => setName(v)}
                  allowClear
                />
              </Form.Item>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                name="name"
                label={t("label-name")}
                style={{ display: "flex", flexDirection: "column", width: "100%" }}
                placeholder={t("placeholder-choose-name")}
                rules={[
                  {
                    required: true,
                    message: t("rules-name-required"),
                  },
                ]}
              >
                <Select
                  id="create-account-name-input"
                  options={accountPhonesNames}
                  onChange={v => form.setFieldsValue({ phone: accountPhones.find(p => v.includes(p.value))?.value || ""  })}
                  allowClear
                />
              </Form.Item>
            </div>
            <Form.Item
              name="phone"
              label={t("label-phone")}
              placeholder={t("placeholder-add-phone")}
              rules={[
                {
                  required: true,
                  message: t("rules-phone-required"),
                },
              ]}
            >
              <Select
                id="create-account-phone-input"
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
            <Form.Item
              id="create-account-description-input"
              name="description"
              label={t("label-description")}
              placeholder={t("placeholder-add-description")}
              rules={[
                {
                  required: true,
                  message: t("rules-description-required"),
                },
              ]}
            >
              <TextArea id="create-account-description-input" rows={4} type="phone" />
            </Form.Item>
            {user !== null &&
              <Form.Item
                label={t("label-yp-accounts")}
                name="accounts"
                required
                rules={[
                  {
                    required: true,
                    message: t("rules-accounts-required"),
                  },
                ]}
              >
                <Select
                  id="create-account-ypaccounts-input"
                  mode="multiple"
                  loading={isLoading}
                  style={{ width: '100%' }}
                  placeholder={t("placeholder-select-yp-accounts")}
                  options={ypAccounts}
                  allowClear
                />
              </Form.Item>
            }
          </Form>
        </Spin>
      </div>
      <a onClick={handleDownload} style={{ textDecoration: "underline", color: "#052656", display: "flex", justifyContent: "center", marginTop: "auto" }}>
        {t("label-download-guide")}
      </a>
    </Drawer>
  );
};

export default CreateAccountDrawer;
