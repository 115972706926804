import { Route, Routes } from "react-router-dom";
import Account from "../../pages/Account/Account";
import CompanyRates from "../../pages/CompanyRates/CompanyRates";
import Home from "../../pages/Home/home";
import RatesProcess from "../../pages/RatesProcess/RatesProcess";
import Companies from '../../pages/Companies/Companies';
import Users from "../../pages/Users/users";
import Balance from "../../pages/Balance/Balance";
import Contact from "../../pages/Contact/Contact";
import Campaign from "../../pages/Campaing/Campaing";
import CampaignReports from "../../pages/CampaignReports/CampaignReports";
import HistoryBalance from "../../pages/HistoryBalance/HistoryBalance";
import CampaignShortlinks from "../../pages/CampaignShortlinks/CampaignShortlinks";
import CampaignWhatsapp from "../../pages/CampaignWhatsapp/CampaignWhatsapp";
import Contacts from "../../pages/Contacts/Contacts";
import HistoryContacts from "../../pages/HistoryContacts/HistoryContacts";
import ShortlinksReport from "../../pages/ShortlinksReport/ShortlinksReport";
import HistoryTemplates from "../../pages/HistoryTemplates/HistoryTemplates";
import CampaingVoz from "../../pages/CampaignVoz/CampaignVoz";
import ApiKeys from "../../pages/ApiKeys/ApiKeys";
import SmsApiKeysDocumentation from "../../pages/SmsApiKeysDocumentation/SmsApiKeysDocumentation";
import HistoryCampaign from "../../pages/HistoryCampaign/HistoryCampaign";
import HistoryBalanceCarrier from "../../pages/HistoryBalanceCarrier/HistoryBalanceCarrier";
import WebHookUrlMO from "../../pages/WebHookUrlMO/WebHookUrlMO";
import WhatsAppBusinessChannel from "../../pages/WhatsApp/WhatsAppBusinessChannel/WhatsAppBusinessChannel";
import WhatsAppTemplates from "../../pages/WhatsApp/WhatsAppTemplates/";
import BulkWhatsApp from "../../pages/WhatsApp/BulkWhatsApp/BulkWhatsApp";
import CreateWhatsAppTemplate from "../../pages/WhatsApp/WhatsAppTemplates/Create/Create";

const Routers = ({data}) => {
  const components = {
    Home,
    Account,
    RatesProcess,
    CompanyRates,
    Companies,
    Users,
    Balance,
    HistoryBalance,
    HistoryBalanceCarrier,
    Contact,
    Campaign,
    CampaignReports,
    CampaignShortlinks,
    //CampaignRcs,
    CampaignWhatsapp,
    Contacts,
    HistoryContacts,
    ShortlinksReport,
    HistoryTemplates,
    CampaingVoz,
    ApiKeys,
    SmsApiKeysDocumentation,
    HistoryCampaign,
    WebHookUrlMO,
    WhatsAppBusinessChannel,
    WhatsAppTemplates,
    BulkWhatsApp,
    CreateWhatsAppTemplate,
  };

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {data && data.map(item => (
        <Route 
          key={item.id} 
          path={item.path} 
          element={getComponent(item.component)} 
        />
      ))}
      <Route path="*" element={<Home />} />
    </Routes>
  );

  function getComponent(name) {
    const Component = components[name];    
    return Component ? <Component /> : <div>Not Found</div>;
  }
};

export default Routers;
