import { Steps } from "antd";
import { Fragment, useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import SelectAccount from "../../components/CampaignShortlinks/SelectAccount/SelectAccount";
import styles from "./CampaignShortlinks.module.css";
import useHeaderOptions from '../../Hooks/useHeaderOptions';
import TYPES from '../../reducers/types'
import useSpinner from '../../Hooks/useSpinner';
import accountApi from "../../Api/AccountApi";
import useAuth from '../../Hooks/useAuth';
import CONSTANTS, { CHANELLIST } from '../../utils/const';
import campaignReducer, { initialCampaignState } from '../../reducers/campaignShortlinksReducer';
import Schedule from "../../components/CampaignShortlinks/Schedule/Schedule";
import { filterTemplatesByAccount, filterdAccountsByUser, notificationError, notificationOk } from "../../utils/helpers";
import UploadFile from "../../components/CampaignShortlinks/UploadFile/UploadFile";
import HeaderMap from "../../components/CampaignShortlinks/HeaderMap/HeaderMap";
import ContentMessage from "../../components/CampaignShortlinks/ContentMessage/ContentMessage";
import TestMessage from "../../components/CampaignShortlinks/TestMessage/TestMessage";
import CampaignApi from "../../Api/CampaignApi";
import CountrySummary from "../../components/CampaignShortlinks/CountrySummary/CountrySummary";
import CampaignSummary from "../../components/CampaignShortlinks/CampaignSummary/CampaignSummary";
import ModalConfirmation from "../../components/ModalConfirmation/ModalConfirmation";
import { useNavigate} from "react-router-dom";
import TemplateApi from "../../Api/TemplatesApi";
import * as XLSX from 'xlsx';
import ShortlinkApi from "../../Api/ShortlinkApi";
import useSteper from "../../Hooks/useSteper";
const { Step } = Steps;

const CampaignShortlinks = () => {

  const [t] = useTranslation("shortlink-campaign");
  const [campaingState, campaingDispacth] = useReducer(campaignReducer, initialCampaignState);

  const [current, setCurrent] = useState(0);
  const [steps, setSteps] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [message, setMessage] = useState(null);
  const [modalCampaignVisible, setModalCampaignVisible] = useState(false);
  const [modalCanelVisible, setModalCanelVisible] = useState(false);
  const [modalBillingVisible, setModalBillingVisible] = useState(false);
  const [unavailableBalance, setUnavailableBalance] = useState(false);

  const { GetAccountsByCompanyId } = accountApi();
  const { sendCampaign, campaigBudget, deleteCampaign, addDataFile, addDataFileWithContacts } = CampaignApi();
  const { getContactsByGorup, addTemplates, getTemplatesByChannel } = TemplateApi();
  const { generateShortlink } = ShortlinkApi();

  const { headerOptionsDispacth } = useHeaderOptions();
  const { spinnerDispacth } = useSpinner();
  const { auth } =  useAuth();
  const navigate = useNavigate();  
  const { breadcrumbDispacth} = useSteper();
  useEffect(() => {

    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.DEFAULT_OPTION,
      payload: TYPES.HEADER_OPTIONS.DEFAULT_OPTION
    });

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
    });
    
    getInitalData()
      .then(response => {
        
        const { accountByUser, templatesByChannel } =  response;
        
        setAccounts(accountByUser);
        setTemplates(templatesByChannel);

        setSteps([
          {
            title: t("steper.one")
          },
          {
            title: t("steper.two")
          },
          {
            title: t("steper.three")
          },
          {
            title: t("steper.four")
          }
        ]);

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
        });
      }).catch(error => {
        console.error(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
        });
      });
  }, [t]);
  useEffect(() => {

    breadcrumbDispacth({
      type: TYPES.BREADCRUMB.SELECT_STEP,
      payload: t("breadcrumb.steper-account")
    });
    /*Se deja vacío el segundo parámetro para el primer paso*/
  }, []);
  const getInitalData = async () => {
    let accountByCompany = await GetAccountsByCompanyId(auth.companyId, auth.accountAccessToken);
    let accountByUser =  filterdAccountsByUser(accountByCompany, auth.userAccounts, auth.role)
    let templatesByChannel = await getTemplatesByChannel(auth.companyId, CHANELLIST.SHORTLINK.key)
    let templatesByAccount = filterTemplatesByAccount(templatesByChannel, accountByUser, auth.role);

    return {accountByUser: accountByUser, templatesByChannel: templatesByAccount}
  }

  
  const handleChangeAccount = value => {
    const ac = accounts.find(x => x.accountId === value);
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_ACCOUNT,
      payload: ac
    })
  };

  const handleTemplateChange = value => {
    let template = templates.find(x => x.id === value);
    handleChangeAccount(template.accountId);

    campaingDispacth({
      type: TYPES.CAMPAIGN.SELECT_TEMPLATE,
      payload: template
    })

    if(template.groupId) {
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_CONTACT,
        payload: template.groupId
      })

      setContactsData(template.contacts, true);
    } else {
      setContactsData(template.contacts, false);
    }

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_SENDERID,
      payload: template.channelParams.SenderId
    });

    generateShortlink(template.channelParams.Link)
    .then(response => {
      
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_SHORTLINK,
        payload: response
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_URL,
        payload: template.channelParams.Link
      });
    })
    .catch(error => {
      console.error(error);
    })

    handleChangeMessage({
      message: template.channelParams.MessageBase,
      html: ""
    });
  }

  const setContactsData = (contacts, isLoadContacts) => {
    
    const mobileNumberName = "Mobile Number";
    let firstRow = contacts[0];

    let fields = Object.keys(firstRow);
    var arrayContainsMobileNumber = (fields.indexOf("mobileNumber") > -1);
    
    if(arrayContainsMobileNumber) {

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN,
        payload: mobileNumberName
      });

      let fileHeader = firstRow.wildCards.map((x, index) => { return {
        column: index + 1,
        name: x.key
      }});

      let customfield = fileHeader.map(x => { return x.name });

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
        payload: customfield
      })

      fileHeader.unshift({column: 0, name: mobileNumberName});

      let count = contacts.length > 3 ? 3 : contacts.length;
      let fileData = [];

      for (let index = 0; index < count; index++) {
        const row = contacts[index];
        let data = { row: index, value: [] }

        let value = row.wildCards.map((x, index) => { return {
          column: index + 1,
          value: x.value
        }});

        value.unshift({column: 0, value: row.mobileNumber});
        data.value = value;
        fileData.push(data);          
      }

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_FILEDATA,
        payload: fileData
      })
      
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_SELECTEDHEADERS,
        payload: fileHeader
      })
  
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_FILEHEADERS,
        payload: fileHeader
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.LOAD_CONTACTS,
        payload: isLoadContacts
      });

    } else {
      console.error("The object doesn`t have mobileNumber property");
    }
  }

  const handleCampaignNameChange = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CAMPAINGNAME,
      payload: value
    })
  };

  const handleChangeUrl = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_URL,
      payload: value
    });

    if(value === "") {
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_SHORTLINK,
        payload: ""
      });
    }
  }

  const renderLeftCard = () => {
    switch (current) {
      case 0:
        return <SelectAccount 
          accounts={accounts}
          templates={templates}
          onTemplateChange={handleTemplateChange}
          onAccountChange={handleChangeAccount}
          onCampaignNameChange={handleCampaignNameChange}
          defaultSelected={campaingState.account?.accountId}
          defaultCampaignName={campaingState.campaignName}
        />
      case 1:
        return <UploadFile
          accountId={campaingState.account.accountId}
          onUploadFile={handleUploadFile}
          onRemoveFile={handleRemoveFile}
          defaultFile={campaingState.file}
          defaultContac={campaingState?.contactSelected}
          onChengeContact={onChengeContact}
        />;
      case 2:
        return <ContentMessage
          customFields={campaingState.headersMap.customFields}
          defaultSenderId={campaingState.senderId}
          defaultUrl={campaingState.url.replace("https://", "")}
          defaultMessage={campaingState.message}
          onChangeMessage={handleChangeMessage}
          onChangeSender={handleChangeSender}
          onChangeWildCards={handleChangeWildCards}
          onChangeUrl={handleChangeUrl}
          onChangeShortLink={onChangeShortLink}
          onSaveTemplate={onSaveTemplate}
          validateMessageWildcard={validateMessageWildcard} // Pasar la función de validación
        />
      case 3:
        return <CountrySummary
          campaignId={campaingState.campaignId}
          summary={campaingState.summary}
        />
    }    
  }

  const renderRightCard = () => {
    switch (current) {
      case 0:
        return <Schedule 
          changeSchedule={changeSchedule} />
      case 1:
        return <HeaderMap
          headers={campaingState.fileHeaders}
          fileData={campaingState.fileData}
          loadContacts={campaingState.loadContacts}
          hasTemplate={campaingState.template ? true : false}
          defaultHeaderSelected={campaingState.headersMap.selectedHeaders ? campaingState.headersMap.selectedHeaders : null}
          defaultChecked={campaingState.headersMap.customFields}
          onChangeHeaders={handleChangeHeaders}
          onChangeMobileNumberField={handleMobileNumberField}
          onChangeCustomFields={handleChangeCustomFields}
          defaultPhoneFieldName={campaingState.headersMap.mobileNumberColumn}
          defaultWildcardSelected={campaingState.headersMap.customFields.length > 0 ? campaingState.headersMap.customFields.map(x => { return {label: x, value: x} }) : []}
        />
      case 2:
        return <TestMessage
          message={message}
          accountId={campaingState.account.accountId}
          senderId={campaingState.senderId}
          customFields={campaingState.headersMap.customFields}
          fileHeaders={campaingState.fileHeaders}
          fileData={campaingState.fileData}
          defualtShortLink={campaingState.shortlink}      
        />
      case 3:
        return <CampaignSummary
          accountId={campaingState.account.accountId}
          campaignName={campaingState.campaignName}
          totalMessage={campaingState.fileRows}
          summary={campaingState.summary}
          schedule={campaingState.scheduleData.Schedule}
          onBalance={setUnavailableBalance}
        />
    }
  }

  const nextStep = () => {

    switch (current) {
      case 0:
        stepOne();
        break;
      case 1:
        stepTwo();
        break;
      case 2:
        stepThree();
        break;
      default:
        console.log('deafult step case')
        break;
    }
  };

  const prevStep = () => {
    let prevStepValue = current - 1;
    let valueName = "";  
  
    switch (prevStepValue) {
      case 0:
        valueName = t("breadcrumb.steper-account");
        break;
      case 1:
        valueName = t("breadcrumb.steper-destination");
        break;
      case 2:
        valueName = t("breadcrumb.steper-content");
        break;
      case 3:
        valueName = t("breadcrumb.steper-summary");
        break;
      default:
        break;
    }
   
    breadcrumbDispacth({
      type: TYPES.BREADCRUMB.SELECT_STEP,
      payload: valueName
    });  
 
    setCurrent(prevStepValue);
    switch (current) {
      case 1:
        if(!campaingState.template) {
          handleRemoveFile();
        }
        setCurrent(current - 1);
        break;
      case 2:

        if(!campaingState.template) {
        
          handleChangeSender("");
          handleChangeMessage({
            message: "",
            html: ""
          });

          campaingDispacth({
            type: TYPES.CAMPAIGN.ADD_URL,
            payload: ""
          });
        }

        let customFields = [...campaingState.headersMap.customFields];
        customFields = customFields.filter(x => x !==  CONSTANTS.SHORTLINK);
        
        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
          payload: customFields
        });
        

        setCurrent(current - 1);
        break;
      case 3:

        handleChangeMessage({
          message: campaingState.message.message,
          html: ""
        });

        setCurrent(current - 1);
        break;
      default:
        console.log('deafult step case')
        break;
    }
  }

  const stepOne = () => {

    if (!campaingState.account) {
      notificationError(t("account.required-account-field"));
      return;
    }

    if (!campaingState.campaignName) {
      notificationError(t("account.required-campaign-name-field"));
      return;
    }

    if (!campaingState.scheduleData) {
      notificationError(t("schedule.notification-schedule-configure"));
      return;
    }

    let percentage = 0;
    let hasNullSchedules = false;

    if (campaingState.scheduleData.scheduletype === "Phases") {
      
      campaingState.scheduleData.Schedule.forEach(x => {
        percentage += x.percentage;
        if (!hasNullSchedules) {
          hasNullSchedules = x.DateScheduled === null;
        }
      });

      if (percentage < 100 || percentage > 100) {
        notificationError(t("schedule.notification-schedule-phases-percentage"));
        return;
      }

      if (hasNullSchedules) {
        notificationError(t("schedule.notification-schedule-phases-date"));
        return;
      }
    }

    changeStep();
  };

  const stepTwo = () => {

    if (!campaingState.template && !campaingState.loadContacts && !campaingState.file) {
      notificationError(t("upload-file.alert-mandatory-csv"));
      return;
    }

    if (!campaingState.headersMap.mobileNumberColumn) {
      notificationError(t("map-header.alert-mobile-number"));
      return;
    }

    let customFields = [...campaingState.headersMap.customFields];
    customFields.unshift(CONSTANTS.SHORTLINK);

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
      payload: customFields
    })

    changeStep();
  };

  const stepThree = () => {

    if (campaingState.senderId === "") {
      notificationError(t("content-message.notification-error-sender-id"));
      return;
    }

    if (campaingState.shortlink === "") {
      notificationError(t("content-message.notification-error-url"));
      return;
    }

    if (campaingState.message.message === "") {
      notificationError(t("content-message.notification-mandatory-message"));
      return;
    }

    if (!validateMessageWildcard()) {
      notificationError(t("content-message.notification-error-shortlink"));
      return;
    }

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.SUMMARY }
    });

    if(campaingState.loadContacts || campaingState.template) {
      addDataFileByContacts();
    } else {
      addDataFileByFile();
    }
    
  }

  const addDataFileByFile = () => {
    const formData = new FormData();
    formData.append('files[]', campaingState.file);

    const json = JSON.stringify({ ...campaingState.headersMap, Message: campaingState.message.message, Link: campaingState.url  });
    const blob = new Blob([json], {
      type: 'application/json'
    });

    formData.append("data", blob);

    let campaign = {
      CampaignName: campaingState.campaignName,
      AccountId: campaingState.account.accountId,
      CompanyId: auth.companyId,
      UserId: auth.userName,
      Message: campaingState.message.message,
      SenderId: campaingState.senderId,
      CampaignSchedule: campaingState.scheduleData
    }

    const blobCampaign = new Blob([JSON.stringify(campaign)], {
      type: 'application/json'
    });

    formData.append("campaign", blobCampaign);

    addDataFile(formData, auth.accessToken).then(res => {
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_FILEROWS,
        payload: res.fileRows
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_CAMPAIGNID,
        payload: res.campaignId
      })

      campaigBudget(res.campaignId).then(result => {
        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_SUMMARY,
          payload: result
        })

        changeStep();
      })
      .catch(error => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBALANCEBYACCOUNT }
        });
        notificationError(t("summary.notification-error-calculated-campaign"));
        console.error(error);
      });

    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.SUMMARY }
      });
      notificationError(error.message);
      console.error(error);
    });
  }

  const addDataFileByContacts = () => {
    const formData = new FormData();

    const json = JSON.stringify({ ...campaingState.headersMap, 
      Message: campaingState.message.message,
      Link: campaingState.url,
      GroupId: campaingState.contactSelected,
      TemplateId: campaingState.template?.id
    });

    const blob = new Blob([json], {
      type: 'application/json'
    });

    formData.append("data", blob);

    let campaign = {
      CampaignName: campaingState.campaignName,
      AccountId: campaingState.account.accountId,
      CompanyId: auth.companyId,
      UserId: auth.userName,
      Message: campaingState.message.message,
      SenderId: campaingState.senderId,
      CampaignSchedule: campaingState.scheduleData
    }

    const blobCampaign = new Blob([JSON.stringify(campaign)], {
      type: 'application/json'
    });

    formData.append("campaign", blobCampaign);

    addDataFileWithContacts(formData, auth.accessToken).then(res => {
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_FILEROWS,
        payload: res.fileRows
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_CAMPAIGNID,
        payload: res.campaignId
      })

      campaigBudget(res.campaignId).then(result => {
        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_SUMMARY,
          payload: result
        })

        changeStep();
      })
      .catch(error => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBALANCEBYACCOUNT }
        });
        notificationError(t("summary.notification-error-calculated-campaign"));
        console.error(error);
      });

    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.SUMMARY }
      });
      notificationError(t("summary.notification-error-upload-file"));
      console.error(error);
    });
  }

  const changeStep = () => {
    let nextStepValue = current + 1;
    let valueName = "";
    if(nextStepValue == 0){
      valueName = (t("breadcrumb.steper-account"))
    }
    if(nextStepValue == 1){
      valueName = (t("breadcrumb.steper-destination"))
    }
    if(nextStepValue == 2){
      valueName = (t("breadcrumb.steper-content"))
    }
    if(nextStepValue == 3){
      valueName = (t("breadcrumb.steper-summary"))
    }
    breadcrumbDispacth({
      type: TYPES.BREADCRUMB.SELECT_STEP,
      payload: valueName
    });
    setCurrent(nextStepValue);
  }

  const changeSchedule = (schedule) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_SCHEDULE,
      payload: schedule
    })
  }

  const handleRemoveFile = () => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_FILE,
      payload: null
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_FILEHEADERS,
      payload: []
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN,
      payload: null
    })
    
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
      payload: []
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_SELECTEDHEADERS,
      payload: []
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CONTACT,
      payload: null
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.SELECT_TEMPLATE,
      payload: null
    })

    handleChangeMessage({
      message: "",
      html: ""
    });
  };

  const handleUploadFile = (file, fileType) => {

    handleRemoveFile();

    if (file) {

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN,
        payload: ""
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_CONTACT,
        payload: null
      })
  
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_FILE,
        payload: file
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.LOAD_CONTACTS,
        payload: false
      })

      switch (fileType) {
        case CONSTANTS.FILETYPES.CSV.NAME:
          processCsvFile(file);
          break;
        case CONSTANTS.FILETYPES.EXCEL.NAME:
          processExcelFile(file);
          break;      
        default:
          break;
      }
    }
  };

  const processCsvFile = (file) => {
    const reader = new FileReader();
    reader.onload = onLoadCsvFileInMemory;
    reader.readAsText(file);
  }

  const processExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = onLoadExcelFileInMemory;
    reader.readAsBinaryString(file);
  }

  const onLoadCsvFileInMemory = event => {
    var csv = event.target.result;
    fileProcess(csv);
  }

  const onLoadExcelFileInMemory = event => {
    /* Parse data */
    const bstr = event.target.result;
    const wb = XLSX.read(bstr, {type:'binary'});
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const csv = XLSX.utils.sheet_to_csv(ws, {header:1});
    fileProcess(csv);
  }

  const fileProcess = csv => {
    var rows = csv.split('\n');

    if (rows.length < 2) {
      notificationError(t("upload-file.alert-file-rows-validation"));
      handleRemoveFile();
      return;
    }

    let separator = rows[0].includes(',') ? ',' : ';'

    var cols = rows[0].split(separator);
    let headers = [];

    if(cols.length > 7) {
      notificationError(t("upload-file.alert-file-columns-validation"));
      handleRemoveFile();
      return;
    }

    for (let j = 0; j < cols.length; j++) {
      headers.push({
        name: cols[j].replace(/(\r\n|\n|\r)/gm, "").trim(),
        column: j
      });
    }

    let data = [];
    let count = rows.length > 4 ? 4 : rows.length

    for (let i = 1; i < count; i++) {

      cols = rows[i].split(separator);
      let row = [];

      if (cols.length !== headers.length) {
        notificationError(t("upload-file.alert-file-rows-columns-validation"));
        handleRemoveFile();
        return;
      }

      for (let j = 0; j < cols.length; j++) {
        row.push({
          value: cols[j].replace(/(\r\n|\n|\r)/gm, ""),
          column: j
        });
      }

      data.push({
        value: row,
        row: i
      });

    }

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_FILEDATA,
      payload: data
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_FILEHEADERS,
      payload: headers
    })
  }

  const handleChangeHeaders = (headerData) => {
    let headersMap = [...campaingState.headersMap.selectedHeaders];
    if(headersMap.length == 0) {
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_SELECTEDHEADERS,
        payload: headerData
      })
    } else {
      let phoneHeader = headersMap.find(x => x.name == campaingState.headersMap.mobileNumberColumn);
      headerData.push(phoneHeader);
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_SELECTEDHEADERS,
        payload: headerData
      })
    }
  }

  const handleMobileNumberField = (value) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN,
      payload: value
    })
  };

  const handleChangeCustomFields = (value) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
      payload: value
    })
    
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_MESSAGE,
      payload: {
        message: "",
        html: ""
      }
    })
    setMessage("");
  };

  const handleChangeMessage = value => {
    
    if(value.message) {
      setMessage(value.message);
    } else 
    {
      setMessage(" ");
    }

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_MESSAGE,
      payload: value
    })
  };

  const handleChangeSender = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_SENDERID,
      payload: value
    })
  }

  const handleChangeWildCards = (wildcardsList) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_WILDCARDS,
      payload: wildcardsList
    })
  }

  const onChangeShortLink = (value) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_SHORTLINK,
      payload: value
    })
  }

  const handleCancelConfirmCampaign = () => {
    setModalCampaignVisible(false);
  }

  const handleConfirmCampaign = () => {

    if(unavailableBalance == false) {
      
      setModalCampaignVisible(false);
    
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.SENDCAMPAIGN }
      });

      let campaign = {
        CampaignId: campaingState.campaignId,
        CampaignName: campaingState.campaignName,
        AccountId: campaingState.account.accountId,
        CompanyId: auth.companyId,
        UserId: auth.userName,
        SenderId: campaingState.senderId,
        Message: campaingState.message.message,
        FileRows: campaingState.fileRows,
        CampaignSchedule: campaingState.scheduleData
      }

      sendCampaign(campaign, auth.accessToken)
        .then(() => {
          
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.SENDCAMPAIGN }
          });

          notificationOk(t("summary.notification-ok-campaign"));
          navigate("Credits");
        }).catch(error => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.SENDCAMPAIGN }
          });
          notificationError(t("summary.notification-error-campaign"));
          console.error(error);
        });

    } else {
      if(auth.role === CONSTANTS.ROLES.COMPANY_ADMIN) {
        setModalBillingVisible(true);
      } else {
        notificationError("Please contact your Administrator to buy credits");
        setTimeout(() => {
          navigate(0);
        }, 2000);
      }
    }
  }

  const handleCancelCampaign = () => {
    setModalCanelVisible(false);
  }

  const handleOkCancelCampaign = () => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.DELETECAMPAIGN }
    });

    if(campaingState.campaignId) {
      deleteCampaign(campaingState.campaignId)
        .then(response => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETECAMPAIGN }
          });
          //changeStepfromBreadCrumb(0);
          navigate(0);
        })
        .catch(error => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETECAMPAIGN }
          });
          notificationError("An error occurred while deleting campaign");
          console.error(error);
          setModalCanelVisible(false);
        });
    } else {
      //changeStepfromBreadCrumb(0);
      navigate(0);
    }
  }

  const handleCancelBilling = () => {
    setModalBillingVisible(false);
    navigate(0);
  }

  const handleOkBilling = () => {
    setModalBillingVisible(false);
    navigate("Credits");
  }

  const onChengeContact = (groupId) => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.CONTACTLIST }
    });

    campaingDispacth({
      type: TYPES.CAMPAIGN.SELECT_TEMPLATE,
      payload: null
    })

    handleChangeMessage({
      message: "",
      html: ""
    });

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CONTACT,
      payload: groupId
    })

    getContactsByGorup(groupId)
    .then(response => {

      setContactsData(response, true);

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTLIST }
      });

    })
    .catch(error => {
      console.error("error", error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTLIST }
      });
    })
  }

  const onSaveTemplate = (templateName) => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.SAVETEMPLATE }
    });

    const formData = new FormData();

    if(!campaingState.loadContacts) {
      formData.append('files[]', campaingState.file);
    }

    const json = JSON.stringify({ ...campaingState.headersMap  });
    const blob = new Blob([json], {
      type: 'application/json'
    });

    formData.append("data", blob);

    let campaign = {
      TemplateName: templateName,
      AccountId: campaingState.account.accountId,
      CompanyId: auth.companyId,
      UserId: auth.userName,
      SenderId: campaingState.senderId,
      Link: campaingState.url,
      MessageBase: campaingState.message.message,
      GroupId: campaingState.loadContacts ? campaingState.contactSelected : null,
      ChannelId: CHANELLIST.SHORTLINK.key
    }

    const blobCampaign = new Blob([JSON.stringify(campaign)], {
      type: 'application/json'
    });

    formData.append("campaign", blobCampaign);

    addTemplates(formData)
    .then(() => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.SAVETEMPLATE }
      });
      notificationOk(t("content-message.notification-ok-template"));
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.SAVETEMPLATE }
      });
      notificationError(t("content-message.notification-error-template"));
      console.error(error);
    })
  }

  const validateMessageWildcard = () => {
    // Selecciona el área del mensaje
    const messageArea = document.getElementById('message-area');
  
    if (!messageArea) {
      console.error('Message area not found');
      return false;
    }
  
    // Selecciona todos los wildcards dentro del área del mensaje
    const wildcards = messageArea.querySelectorAll('[class*="MessageWildcard_message-area-wildcard"]');
  
    if (wildcards.length === 0) {
      console.warn('No wildcards found in the message area');
      return false;
    }
  
    // Itera sobre cada wildcard para verificar si contiene 'shortlink' en su clase
    for (let wildcard of wildcards) {
      const classNames = wildcard.className.split(' ');
  
      console.log('Wildcard classes:', classNames); // Agrega este registro para depurar
  
      for (let className of classNames) {
        if (className.includes('shortlink')) {
          console.log('Found shortlink wildcard:', wildcard); // Agrega este registro para depurar
          return true; // Si encuentra la palabra 'shortlink', devuelve true
        }
      }
    }
  
    console.log('No shortlink wildcard found'); // Agrega este registro para depurar
    return false; // Si no encuentra ningún wildcard con 'shortlink', devuelve false
  };
  
  // Llama a la función cuando el DOM esté completamente cargado
  document.addEventListener('DOMContentLoaded', validateMessageWildcard);
  
  
  
  return (
    <Fragment>
      <div className="yp-workspace">
        <div className={styles['step-header']}>
          <Steps labelPlacement="horizontal" current={current} direction="horizontal">
            {steps.map(item => (
              <Step key={item.title} title={item.title}/>
            ))}
          </Steps>
        </div>
        <div className={styles['step-content']}>
          <div className={styles['step-content-card']}>
            {
              renderLeftCard()
            }
          </div>
          <div className={styles['step-content-card']}>
            {
              renderRightCard()
            }
          </div>
        </div>
      </div>
      <div className={styles['step-buttons-container']}>
        <div className={styles['step-buttons']}>
          {current < steps.length && (
            <Button style={{ width: 150, marginRight: '10px' }} type="secondary" onClick={() => setModalCanelVisible(true)}>
              {t("actions-buttons.cancel")}
            </Button>
          )}
          {current > 0 && current < steps.length  && (
            <Button style={{ width: 150, marginRight: '10px' }} type="optional" onClick={() => prevStep()}>
              {t("actions-buttons.previous")}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button style={{ width: 150 }} type="primary" onClick={() => setModalCampaignVisible(true)}>
              {t("actions-buttons.done")}
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button id="next_buton" style={{ width: 150 }} type="primary" onClick={() => nextStep()}>
              {t("actions-buttons.next")}
            </Button>
          )}
        </div>
      </div>
      <ModalConfirmation
        key={1}
        open={modalBillingVisible}
        title={t("modal-campaign-credits.title")}
        onCancel={handleCancelBilling}
        onOk={handleOkBilling}
        okBtnText={t("modal-campaign-credits.btn-ok")}
      >
        <p>{t("modal-campaign-credits.content")}</p>
      </ModalConfirmation>
      <ModalConfirmation
        key={2}
        open={modalCanelVisible}
        title={t("modal-cancel-campaign.title")}
        onCancel={handleCancelCampaign}
        onOk={handleOkCancelCampaign}
      >
        <p>{t("modal-cancel-campaign.content")}</p>
      </ModalConfirmation>
      <ModalConfirmation
        key={3}
        open={modalCampaignVisible}
        title={t("modal-generate-campaign.title")}
        onCancel={handleCancelConfirmCampaign}
        onOk={handleConfirmCampaign}
        okBtnText={t("modal-generate-campaign.btn-ok")}
      >
        <p>{t("modal-generate-campaign.content")}</p>
      </ModalConfirmation>
    </Fragment>
  );
}
 
export default CampaignShortlinks;