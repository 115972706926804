export const accountScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/accountms/Account.Write`,
    `https://${process.env.REACT_APP_B2C_TENANT}/accountms/Account.Read`];

export const companyScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/companyms/Company.Write`,
    `https://${process.env.REACT_APP_B2C_TENANT}/companyms/Company.Read`];

export const balanceScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/balancems/Balance.Read`,
    `https://${process.env.REACT_APP_B2C_TENANT}/balancems/Balance.Write`];

export const campaignScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/campaignms/Campaign.Read`,
    `https://${process.env.REACT_APP_B2C_TENANT}/campaignms/Campaign.Write`];

export const campaignVozScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/campaignvoicems/CampaignVoice.Read`,
    `https://${process.env.REACT_APP_B2C_TENANT}/campaignvoicems/CampaignVoice.Write`];

export const shortlinkScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/campaignms/Campaign.Read`,
    `https://${process.env.REACT_APP_B2C_TENANT}/campaignms/Campaign.Write`];;


export const userScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/userms/Users.Read`,
    `https://${process.env.REACT_APP_B2C_TENANT}/userms/Users.Write`];

export const rateScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/ratesms/Rates.Read`,
    `https://${process.env.REACT_APP_B2C_TENANT}/ratesms/Rates.Write`];

export const configurationScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/configurationms/Configuration.Write`,
    `https://${process.env.REACT_APP_B2C_TENANT}/configurationms/Configuration.Read`];

export const dataScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/datams/Data.Read`,
    `https://${process.env.REACT_APP_B2C_TENANT}/datams/Data.Write`];

export const templateScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/templatems/Template.Write`,
    `https://${process.env.REACT_APP_B2C_TENANT}/templatems/Template.Read`];

export const textToSpeechScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/speechms/Speech.Write`,
    `https://${process.env.REACT_APP_B2C_TENANT}/speechms/Speech.Read`];

export const whatsappScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/whatsappms/Whatsapp.Write`,
    `https://${process.env.REACT_APP_B2C_TENANT}/whatsappms/Whatsapp.Read`];

export const rcsScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/rcsms/RCS.Write`,
    `https://${process.env.REACT_APP_B2C_TENANT}/rcsms/RCS.Read`];
    
export const reportScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/reportms/Report.Read`,
    `https://${process.env.REACT_APP_B2C_TENANT}/reportms/Report.Write`];    

export const campaignManagerScopes = [
    `https://${process.env.REACT_APP_B2C_TENANT}/campaignmanagerms/CampaignManager.Write`,
    `https://${process.env.REACT_APP_B2C_TENANT}/campaignmanagerms/CampaignManager.Read`];    