import React, { useContext, useEffect, useState } from "react";
import { Drawer, Input, Form, Button, Popconfirm, Select } from "antd";
import { useTranslation } from "react-i18next";
import { orangeColor, getGradientColor } from "../../../utils/colorsCss";
import { cleanErrorMessage, notificationOk } from '../../../utils/helpers';
import WhatsappApi from "../../../Api/WhatsappApi";
import { notificationError } from "../../../utils/helpers";
import { AuthContext } from "../../../contexts/authRedirect";
import "./WhatsAppBusinessChannel.css"

const CreateAccountDrawer = ({ visible, onClose, accountToEdit, setShouldRefetch, open }) => {
  const [t] = useTranslation("whatsapp-channel");
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { createUpdateWaba, getWabaAccounts } = WhatsappApi();
  const { auth } = useContext(AuthContext);
  const [ypAccounts, setYPAccounts] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getWabaAccounts(auth.companyId)
      .then(accounts => {
        const filtered = (accounts?.data || [])
          .map(account => ({ label: account.accountName, value: account.accountId, disabled: account.wabaPhoneId !== accountToEdit?.wabaPhoneId && account.wabaPhoneId !== null && account.status !== "DECLINED" }));
        setYPAccounts(filtered);
        form.setFieldsValue({ accounts: accountToEdit.accounts })
      })
      .catch(() => {
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }, []);


  return (
    <Drawer
      className="drawer-edit-whatsapp"
      open={visible}
      title={t("title-edit")}      
      width={360}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      footer={
        <div style={{ textAlign: "center" }}>
          <Button
            id="edit-account-cancel-button"
            onClick={() => {
              form.resetFields();
              onClose();
            }}
            style={{ marginRight: 50 }}
            disabled={loading}
          >
            {t("button-cancel")}
          </Button>
          <Popconfirm
            title={t("pop-confirm-title-edit")}
            okButtonProps={{
              "data-testid": "okButtonConfirm", //Important to do test
              id: "edit-account-popconfirm-yes-button"
            }}
            cancelButtonProps={{
              id: "edit-account-popconfirm-no-button"
            }}
            disabled={loading}
            onConfirm={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  createUpdateWaba({ ...values, wabaId: accountToEdit.wabaId, wabaPhoneId: accountToEdit.wabaPhoneId, companyId: auth.companyId, lastModifiedBy: auth.userName }, "PUT")
                    .then((v) => {
                      notificationOk(t("notification-ok-edit"));
                      setShouldRefetch(true)
                    })
                    .catch(e => {
                      notificationError(cleanErrorMessage(e) || t("error-editing-account"), 15);
                    })
                  onClose()
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
            okText={t("yes")}
            cancelText={t("no")}
          >
            <Button type="primary" id="edit-account-confirm-button" disabled={loading}>
              {t("button-confirm")}
            </Button>
          </Popconfirm>
        </div>
      }
    >
      <Form
        data-testid="drawer-content"
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{...accountToEdit, accounts: []}}
      >
        <Form.Item
          name="name"
          label={t("label-name")}
          style={{ display: "flex", flexDirection: "column" }}
          placeholder={t("placeholder-choose-name")}
        >
          <Input id="edit-account-name-input" disabled />
        </Form.Item>
        <Form.Item
          name="phone"
          label={t("label-phone")}
          placeholder={t("placeholder-add-phone")}
        >
          <Input id="edit-account-phone-input" style={{ width: '100%' }} disabled />
        </Form.Item>
        <Form.Item
          name="description"
          label={t("label-description")}
          placeholder={t("placeholder-add-description")}
          rules={[
            {
              required: true,
              message: t("rules-description-required"),
            },
          ]}
        >
          <TextArea id="edit-account-description-input" rows={4} />
        </Form.Item>
        <Form.Item
          label={t("label-yp-accounts")}
          name="accounts"
          rules={[
            {
              required: true,
              message: t("rules-accounts-required"),
            },
          ]}
        >
          <Select
            id="edit-account-ypaccounts-input"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={t("placeholder-select-yp-accounts")}
            options={ypAccounts}
            optionFilterProp="label"
            allowClear
            disabled={loading}
            loading={loading}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreateAccountDrawer;
