import { Tooltip } from 'antd';
import { violetColor } from '../../utils/colorsCss';
import styles from "./HelperButton.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/pro-solid-svg-icons'
import propTypes from 'prop-types'

const HelperButton = ({message, color, id = "", placement = "top" }) => {

  const toolTipColor = color ? color : violetColor ;

  return (
    <Tooltip title={message} color={toolTipColor} overlayInnerStyle={{padding: 20}} placement={placement}>
      <div id={id} className={styles['helper-button']}>
        <span><FontAwesomeIcon icon={ faInfo } /></span>
      </div>
    </Tooltip>
  );
}

HelperButton.propTypes = {
  color: propTypes.string
}

export default HelperButton;