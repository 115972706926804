import React, { useState, useEffect, Fragment } from 'react';
import { Input, Alert } from 'antd';
import CardFilter from "../../components/CardFilter/CardFilter";
import CardCreate from "../../components/CardCreate/CardCreate";
import CreateCompany from "../../components/CreateCompany/CreateCompany";
import CompanyApi from "../../Api/CompanyApi";
import BalanceApi from "../../Api/BalanceApi";
import ConfigurationApi from "../../Api/ConfigurationApi";
import UserApi from "../../Api/UserApi";
import { filterByField } from "../../utils/helpers";
import { notificationOk, notificationError, GetTimeZoneById, GetCurrencyById } from '../../utils/helpers';
import accountApi from "../../Api/AccountApi";
import useAuth from '../../Hooks/useAuth';
import CompanyCard from '../../components/CompanyCard/CompanyCard';
import TYPES from '../../reducers/types';
import CONSTANTS, { CHANELLIST } from '../../utils/const';
import useSpinner from '../../Hooks/useSpinner';
import RatesApi from '../../Api/RatesApi';
import { useTranslation } from 'react-i18next';

const UsersManagment = () => {

  const [t] = useTranslation("company");

  const [showCreateCompany, setShowCreateCompany] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [companiesFiltered, setCompaniesFilteredState] = useState([]);

  //TO DO esto es provicional mientras se genera la logica de autorizacion
  const [authorized, setAuthorizedState] = useState([]);

  const { auth } = useAuth();
  const { spinnerDispacth } = useSpinner();
  const { addBalance, SetAccountBalance } = BalanceApi();
  const { SetCompany, GetCompanies } = CompanyApi();
  const { SetUser } = UserApi();
  const { addRates } = RatesApi()

  const { SetAccount } = accountApi();
  const { getTimeZones } = ConfigurationApi();

  useEffect(() => {

    setAuthorizedState(auth.role);

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.COMPANIES }
    });

    getInitialData()
      .then(result => {

        const { companyResponse, timezoneResponse } = result;
        //console.log("response" + companyResponse)
        let data = [];

        companyResponse.forEach(company => {
          data.push({
            companyId: company.companyId,
            companyName: company.companyName,
            timeZone: company.timezone,
            currency: GetCurrencyById(company.currencyId),
            contactEmail: company.contactEmail,
            balance: company.balance,
            enable: company.enable
          });
        });

        setCompanies(data);
        setTimezones(timezoneResponse);

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.COMPANIES }
        });
      })
      .catch(error => {
        console.error(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.COMPANIES }
        });
        notificationError("An error occurred to get the companies. Please contact to support team");
      })

  }, []); 
  

  useEffect(() => {
    setCompaniesFilteredState(companies);
  }, [companies])

  const getInitialData = async () => {
    let companyResponse = await GetCompanies(auth.accessToken);
    let timezoneResponse = await getTimeZones();
    return { companyResponse: companyResponse, timezoneResponse: timezoneResponse }
  }

  const showDrawer = (value) => {
    setShowCreateCompany(value);
  }

  const onCreateCompany = (company) => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.CREATECOMPANY }
    });

    CreateCompanyProcess(company)
      .then(() => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATECOMPANY }
        });
        showDrawer(false);
      })
  }

  const CreateCompanyProcess = async (data) => {

    try {
      let newCompany = await createCompany(data);
      data.companyId = newCompany.companyId;
      let account = await createMasterAccount(data);
      data.accountId = account.accountId;
      await createCompanyUser(data);
      await createAccountBalance(data);
      var responseRates = await createRates(data, data.fileSms[0], CHANELLIST.SMS.key);

      if (responseRates.isSuccessful === true) {        
        responseRates = await createRates(data, data.fileVoice[0], CHANELLIST.VOICE.key);
        if (responseRates.isSuccessful === true) {
          setCompanies([
            ...companies,
            {
              companyId: newCompany.companyId,
              companyName: newCompany.companyName,
              timeZone: newCompany.timezone,
              currency: GetCurrencyById(newCompany.currencyId),
              contactEmail: newCompany.contactEmail,
              enable: newCompany.enable,
              balance: 0
            }
          ]);

          notificationOk(t("drawer-create-company.alert-create-company-ok"))
        } else {
          errorCompanyProcess('VOICE: ' + responseRates.error);
        }
      } else {
        errorCompanyProcess('SMS: ' + responseRates.error);
      }
    } catch (error) {
      errorCompanyProcess(error.message);
    }
  }

  const createCompany = async (data) => {
    try {

      const { name, currency, email, timezone, ratebase } = data;

      let newCompany = {
        "companyName": name,
        "currencyId": currency,
        "companyTimeZoneId": timezone,
        "contactEmail": email,
        "ratesList": [
          {
            "rateId": ratebase,
            "rateValue": 0.005
          }
        ]
      }

      let response = await SetCompany(newCompany, auth.accessToken);
      return response;

    } catch (error) {
      console.error(error);
      throw new Error("An error occurred creating the company. Please contact to support team ");
    }
  }

  const createMasterAccount = async (data) => {
    try {

      const { name } = data;
      let newAccount = {
        "companyId": data.companyId,
        "name": name,
        "description": name,
        "accountUsers": [],
        "isDefaultAccount": true
      }
      let response = await SetAccount(newAccount);
      return response;
    } catch (error) {
      console.error(error);
      throw new Error("An error occurred creating acount. Please contact to support team ");
    }
  }

  const createCompanyUser = async (data) => {
    try {

      const { companyId, accountId, email, firstName, lastName, displayName, phone } = data;

      let newUserCompany = {
        "companyId": companyId,
        "email": email,
        "firstName": firstName,
        "lastName": lastName,
        "displayName": displayName,
        "description": "User Company",
        "initialState": true,
        "role": "CompanyAdmin",
        "phone": phone,
        "accounts": [
          accountId
        ]
      }

      let response = await SetUser(newUserCompany);
      return response;

    } catch (error) {
      console.error(error);
      throw new Error("An error occurred creating the company user. Please contact to support team ");
    }
  }

  const createAccountBalance = async (data) => {
    try {

      const { companyId, accountId } = data;

      let bodyAccountBalance = {
        "CompanyId": companyId,
        "AvailableBalance": 0,
        "AccountId": accountId
      }

      let response = await SetAccountBalance(bodyAccountBalance);
      return response;

    } catch (error) {
      console.error(error);
      throw new Error("An error occurred creating the balance company. Please contact to support team ");
    }
  }

  const createRates = async (data, file, channelId) => {
    const formData = new FormData();
    formData.append('files[]', file);

    const json = JSON.stringify({ CompanyId: data.companyId, ChannelId: channelId});
    const blob = new Blob([json], {
      type: 'application/json'
    });

    formData.append("data", blob);
    return await addRates(formData);
  }

  const errorCompanyProcess = (message) => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.CREATECOMPANY }
    });
    showDrawer(false);
    notificationError(message);
  }

  const changeFilter = (e) => {
    let value = e.target.value;
    let result = filterByField(companies, "companyName", value);
    setCompaniesFilteredState(result);
  }

  const onAddCompanyBalance = (data) => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.ADDBALANCE }
    });

    addCompanyBalanceProcess(data)
    .then(() => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ADDBALANCE }
      });
      notificationOk("Add balance successfull");
    })
    .catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ADDBALANCE }
      });
      notificationError("An error ocurred adding balance: " + error);
    })
  }

  const addCompanyBalanceProcess = async (data) => {
    await addBalance(data);
    let companyResponse = await GetCompanies(auth.accessToken);

    let companyList = [];

    companyResponse.forEach(company => {
      companyList.push({
        companyId: company.companyId,
        companyName: company.companyName,
        timeZone: GetTimeZoneById(company.companyTimeZoneId),
        currency: GetCurrencyById(company.currencyId),
        contactEmail: company.contactEmail,
        balance: company.balance,
        enable: true
      });
    })
    setCompanies(companyList);
  }

  return (
    <Fragment>
      {
        authorized === "Admin" ? (
          <CardFilter
            title="Company Search"
            filter={
              <Input
                placeholder="Company Name"
                onChange={changeFilter}
              />
            }
            body={
              <div className="yp-grid-cards">
                <CardCreate
                  onClick={() => showDrawer(true)}
                  title={t("card-create-company.label-title")}
                  content={t("card-create-company.label-content")}
                />
              {
                companiesFiltered.map(company => {                 
                  return (
                    <CompanyCard 
                      key={company.companyId}
                      company={company}
                      onAddCompanyBalance={onAddCompanyBalance} 
                    />
                  );
                })
              }
                <CreateCompany
                  timezones={timezones}
                  open={showCreateCompany}
                  onClose={() => showDrawer(false)}
                  onCreate={onCreateCompany}
                />
              </div>
            }
          />
        ) : (
          <Alert message="Your user does not have permissions to see this page" type="error" />
        )
      }
    </Fragment>
  );
}

export default UsersManagment;