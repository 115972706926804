import { Button, Card, Tag } from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import HelperButton from "../../../components/HelperButton/HelperButton";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import CreateAccountDrawer from "./CreateAccountDrawer";
import EditAccountDrawer from "./EditAccountDrawer";
import useSpinner from '../../../Hooks/useSpinner';
import TYPES from '../../../reducers/types';
import { AuthContext } from "../../../contexts/authRedirect";
import CONSTANTS from "../../../utils/const";
import WhatsappApi from "../../../Api/WhatsappApi";
import dayjs from "dayjs";
import "./WhatsAppBusinessChannel.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const { Meta } = Card;

const WhatsAppBusinessChannel = () => {
  const { spinnerDispacth } = useSpinner();
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [showEditAccount, setShowEditAccount] = useState({ visible: false, accountToEdit: null });
  const [shouldRefetch, setShouldRefetch] = useState(true)
  const [accounts, setAccounts] = useState(null)
  const [t] = useTranslation("whatsapp-channel");
  const { auth } = useContext(AuthContext);
  const { getAccounts } = WhatsappApi();

  useEffect(() => {
    if(shouldRefetch) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.COMPANYACCOUNTS }
      });
      getInitialData()
        .then(() => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.COMPANYACCOUNTS }
          });
          setShouldRefetch(false)
        })
    }
  }, [shouldRefetch]);

  const getInitialData = async () => {
    try {
      const _accounts = await getAccounts(auth.companyId);
      setAccounts(_accounts.data)
    }
    catch (error) {
      console.error(error);
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case "APPROVED":
        return t("approved")
      case "DECLINED":
        return t("declined")
      case "AVAILABLE_WITHOUT_REVIEW":
        return t("available_without_review")
      case "EXPIRED":
        return t("expired")
      case "PENDING_REVIEW":
        return t("pending_review")
      case "NONE":
        return t("none")
      case "NON_EXISTS":
        return t("non_exists")
      default:
        return t("unknown")
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "APPROVED":
        return "#006978"
      case "PENDING_REVIEW":
        return "#FF9F30"
      case "DECLINED":
        return "#A3001B"
      default:
        return "gray"
    }
  }

  return (
    <Fragment>
      <div className="yp-workspace">
        <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          <Card
            id='create-new-account'
            key='new'
            style={{ width: 300 }}
            hoverable
            onClick={() => setShowCreateAccount(true)}
            cover={
              <div>
                <div style={{ backgroundColor: "#0092FF", height: "10px" }} />
                <div style={{ backgroundColor: "#052656", minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div style={{ backgroundColor: "#0092FF", padding: "10px", borderRadius: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <PlusOutlined style={{ fontSize: '42px', color: "white" }} />
                  </div>
                </div>
              </div>
            }
          >
            <Meta title={<p style={{ textAlign: "center" }}>{t("create_new_account")}</p>} />
          </Card>
          {(accounts || []).map(a => (
            <Card
              className="waba-card"
              key={a.wabaPhoneId}
              style={{ minWidth: "300px", width: "300px", padding: "0px" }} 
              title={
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", height: "100%", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                    <span style={{ paddingRight: "8px" }}>{t("status")}:</span>
                    <Tag color={getStatusColor(a.status)}>{getStatus(a.status)}</Tag>
                  </div>
                  {a.status !== "DECLINED" && <div style={{ display: "flex" }}>
                    <Button
                      id='edit-account-button'
                      onClick={() => setShowEditAccount({
                        visible: true,
                        accountToEdit: {
                          name: a.name,
                          phone: a.phone,
                          description: a.description,
                          wabaId: a.wabaId,
                          wabaPhoneId: a.wabaPhoneId,
                          accounts: a.accounts.map(a => a.accountId)
                        } 
                      })}
                      icon={<FontAwesomeIcon icon={faPenToSquare} color="white" />}
                      style={{ marginRight: "0px", backgroundColor: "unset", border: "unset !important" }}
                    />
                  </div>}
                </div>
              }
            >
              {a.status === "APPROVED" ? 
                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                  <div style={{ maxWidth: "50%", wordBreak: "break-all" }}>{a.name}</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      id='go-to-template-list-button'
                      type="primary"
                      size="small"
                      style={{ color: "black", fontSize: "12px", marginRight: "0px" }}
                      href="/#/WhatsAppTemplates/List"
                      onClick={() => sessionStorage.setItem("SELECTED_PHONE_FOR_WA_TEMPLATE", JSON.stringify({ phone: a.phone, wabaId: a.wabaId }))}
                    >
                      {t("go_to_template_list")}
                    </Button>
                    <HelperButton id='template-list-button-tooltip' message={t("tooltip_description")} color={getGradientColor(purpleColor)} />
                  </div>
                </div> :
                <p style={{ textAlign: "center" }}>{a.name}</p>
              }
              <div style={{ paddingTop: "16px" }}>
                <p>{t("phone")}: {a.phone}</p>
                <p>{t("description")}: {a.description}</p>
                <p>{t("date")}: {dayjs(a.lastModifiedDate).format("DD/MM/YYYY")}</p>
              </div>
            </Card>
          ))}
        </div>
      </div>
      {showCreateAccount && <CreateAccountDrawer
        visible={showCreateAccount}
        onClose={() => setShowCreateAccount(false)}
        setShouldRefetch={(v) => setShouldRefetch(v)}
      />}
      {showEditAccount.visible && <EditAccountDrawer
        visible={showEditAccount.visible}
        accountToEdit={showEditAccount.accountToEdit}
        onClose={() => setShowEditAccount({ visible: false, accountToEdit: null })}
        setShouldRefetch={(v) => setShouldRefetch(v)}
      />}
    </Fragment>
  );
}
 
export default WhatsAppBusinessChannel;