import { Alert, Col, Form, Input, Row, Select } from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import CONSTANTS from "../../../utils/const";
import encodingSevenBits from "../../../utils/helpers";
import HelperButton from "../../HelperButton/HelperButton";
import MessageWildcard from "../../MessageWildcard/MessageWildcard";
import styles from "./ContentMessage.module.css";
import Button from "../../Button/Button";
import ModalConfirmation from "../../ModalConfirmation/ModalConfirmation";

const { Option } = Select;

const ContentMessage = ({customFields,
  defaultSenderId,
  defaultMessage,
  defaultWildCards,
  voiceSelected,
  onChangeSender,
  onChangeMessage,
  onChangeWildCards,
  onSaveTemplate,
  onChangeVoice}) => {

  const [form] = Form.useForm();

  const [t] = useTranslation("campaign-voz");

  const [senderId, setSenderId] = useState(defaultSenderId.length > 0 ? defaultSenderId : "");
  const [hasMessage, setHasMessage] = useState(false);
  const [isBasicEncoding, setIsBasicEncoding] = useState(true);
  const [specialCharacters, setSpecialCharacters] = useState([]);
  const [modalTemplateVisible, setModalTemplateVisible] = useState(false);
  const [showTemplateNameValidation, setShowTemplateNameValidation] = useState(false);
  const [templateName, setTemplateName] = useState("");
    

  useEffect(() => {
    if(defaultMessage.message) {
      onChangeWildcardMessage(defaultMessage);
    }
  }, [defaultMessage])


  const handleSenderIdChange = e => {
    const value = e.target.value;
    if (value.length <= 12) {
      setSenderId(value);
      onChangeSender(value);
    }
  };

  const onChangeWildcardMessage = message => {
    let ms = message.message.replaceAll(CONSTANTS.WILDCARD, '');
    onChangeMessage(message);
    setHasMessage(message.message.length > 0);

    var isBasic = isBasicEncoding;
    var hasSpecialCharacters = false;

    if (ms.length === 0) {
      setIsBasicEncoding(true);
    } else {

      hasSpecialCharacters = specialCharacters.some(v => ms.includes(v));

      if (hasSpecialCharacters) {
        setIsBasicEncoding(false);
      }
      else {

        let chart = ms.charAt(ms.length - 1);
        isBasic = encodingSevenBits.includes(chart) ? true : false;

        if (isBasic && !hasSpecialCharacters) {
          setIsBasicEncoding(true);
        } else {

          if (isBasic) {
            setSpecialCharacters([...specialCharacters, chart])
          }
          setIsBasicEncoding(false);
        }

        isBasic ? setIsBasicEncoding(true) : setSpecialCharacters([...specialCharacters, chart]);

      }
    }
  }

  const handleOkTemplate = () => {
    if(!templateName) {
      setShowTemplateNameValidation(true);
      return;
    }

    handleCancelTemplate();
    onSaveTemplate(templateName);
  }

  const handleCancelTemplate = () => {
    setModalTemplateVisible(false);
    setShowTemplateNameValidation(false);
    setTemplateName("");
    form.resetFields();
  }

  const handleTemplateNameChange = (e) => {
    let value = e.target.value;
    if(value) {
      setShowTemplateNameValidation(false);
      setTemplateName(value);
    }
  }

  return (
    <>
      <div className={styles['message-form']}>
        <div>
          <Form
            layout="vertical"
          >
            <Form.Item label={t("content-message.label-sender-id")}>
              <Row>
                <Col>
                  <Input
                    style={{ width: 500 }}
                    placeholder="Sender Id"
                    value={senderId}
                    onChange={handleSenderIdChange}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
        <div className={styles['message-sedner-toltip']}>
          <HelperButton 
            message={t("content-message.tooltip-sender-id")}
            color={getGradientColor(purpleColor)}
          />
        </div>
      </div>
      <div className={styles['message-form']}>
        <div>
          <Form
            layout="vertical"
          >            
            <Form.Item label={t("content-message.label-message")}>
              <Row>
                <Col>
                  <MessageWildcard
                    disabled={senderId === ""}
                    contentEditable={true}
                    wildcardsFields={customFields}
                    defaultMessage={defaultMessage}
                    defaultWildCards={defaultWildCards}
                    onChangeMessage={onChangeWildcardMessage}
                    onChangeWildCards={onChangeWildCards}
                  >
                  </MessageWildcard>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
        <div className={styles['message-sedner-toltip']}>
          <HelperButton 
            message={t("content-message.tooltip-url")}
            color={getGradientColor(purpleColor)}
          />
        </div>
      </div>
      <div className={styles['message-form']}>
        <div>
          <Form
            layout="vertical"
          >
            <Form.Item label={t("content-message.label-voice")}>
              <Row>
                <Col>
                  <Select
                    placeholder={t("content-message.placeholder-voice")}
                    value={voiceSelected}
                    onChange={onChangeVoice}
                    style={{ width: 500 }}
                  >
                    {
                      CONSTANTS.LIST.VOICE.map(item =>
                        <Option key={item.key} value={item.key}>{t(item.value)}</Option>
                      )
                    }
                  </Select>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
        <div className={styles['message-sedner-toltip']}>
          <HelperButton 
            message={t("content-message.tooltip-voice")}
            color={getGradientColor(purpleColor)}
          />
        </div>
      </div>
      <div className={styles['save-template']}>
        <Button type="primary" 
          onClick={() => setModalTemplateVisible(true)}
          disabled={senderId.length == 0 || !hasMessage || voiceSelected == null }
        >
          {t("content-message.button-save-template")}
        </Button>
      </div>
      <ModalConfirmation
        open={modalTemplateVisible}
        title={t("content-message.modal-template-title")}
        onCancel={handleCancelTemplate}
        onOk={handleOkTemplate}
        okBtnText={t("content-message.modal-template-btn-ok")}
      >
        <Form
          data-testid="content"
          name="validate_other"
          form={form}
          layout="vertical"
        >
          <Row>
            <Col>
              <p>{t("content-message.modal-template-content")}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="TemplateName"
                label={t("content-message.modal-template-label-name")}
                extra={
                  (
                    showTemplateNameValidation && (
                      <Alert message={t("content-message.modal-template-required-field")} type="error" showIcon />
                    )
                  )
                }
              >
                <Input
                  id="TemplateName"
                  name="TemplateName"
                  style={{ width: 320 }}
                  placeholder={t("content-message.modal-template-placeholder-name")}
                  onChange={handleTemplateNameChange}
                  value={templateName}
                />                
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalConfirmation>
    </>
  );
}
 
export default ContentMessage;