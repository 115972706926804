import { Input, Pagination } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ListGroups.module.css";
import CONSTANTS from "../../../utils/const";
import TableCardCustom from "../../TableCardCustom/TableCardCustom";
import TableCardHeadCustom from "../../TableCardCustom/TableCardHeadCustom/TableCardHeadCustom";
import TableCardBodyCustom from "../../TableCardCustom/TableCardBodyCustom/TableCardBodyCustom";
import TableCardBodyTrCustom from "../../TableCardCustom/TableCardBodyTrCustom/TableCardBodyTrCustom";
import TableCardButtons from "../../TableCardCustom/TableCardButtons/TableCardButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faTrash, faEye } from "@fortawesome/pro-solid-svg-icons";
import ModalConfirmation from "../../ModalConfirmation/ModalConfirmation";
import { dateFormat } from "../../../utils/helpers";
import { v4 as uuidv4 } from "uuid";

const ListGroups = ({groups, onChangeGroupSelected, onDeleteGroup}) => {

  const [t] = useTranslation("contacts-history");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(CONSTANTS.PAGINATION);
  const [totalPage, setTotalPage] = useState(0);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [modalMessage, setModalMessage] = useState("");
  const [modalContactsVisible, setModalContacsVisible] = useState(false);
  const [groupSelected, setGroupSelected] = useState(null);
  const [groupDelete, setGroupDelete] = useState(null);
  const MAX_CHARACTERS = 30;

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  useEffect(() => {
    setTotalPage(groups.length);
    setDataFilteredByPage(groups, 1);
  }, [groups]);

  const setDataFilteredByPage = (items, page) => {
    const index = (page - 1) * itemsPerPage;
    const currentData = items.slice(index, index + itemsPerPage);
    setGroupSelected(currentData[0]);
    setDataFiltered(currentData);
  }

  const onChangePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    setDataFilteredByPage(groups, pageNumber);
  }

  const onSearch = (e) => {
    let value = e.target.value;
    let result = groups.filter(x => x.groupName.toLowerCase().includes(value.toLowerCase()));
    setDataFiltered(result);
    setTotalPage(result.length);
  }

  const onHandelDelete = (groupId) => {
    onDeleteGroup(groupId);
    setCurrentPage(1);
  }

  const onDeleteClick = (group) => {
    setGroupDelete(group);
    let message = t("list-groups.modal-delete-group-content");
    message = message.replaceAll("[name]", `"${group.groupName}"`);
    setModalMessage(message);
    setModalContacsVisible(true);
  }

  const handleConfirmModal = () => {
    setModalContacsVisible(false);
    onHandelDelete(groupDelete.id);
  }

  const onHandlerGroupSelected = (group) => {
    setGroupSelected(group);
    onChangeGroupSelected({ id: group.id, name: group.groupName });
  }

  const isGroupSelected = (groupId) => {
    return groupSelected?.id === groupId;
  } 

  return (
    <div className={styles['content']}>
      <div className={styles['content-title']}>
        <div className={styles['content-formTitle']}>
          <p>{t("list-groups.label-title")}</p>
          <div>
            <Input placeholder={t("list-groups.input-placeholder-search")} onChange={onSearch} style={{width:300}}/>
          </div>
        </div>
        <Pagination
          defaultCurrent={currentPage}
          current={currentPage}
          onChange={(pageNumber) => onChangePagination(pageNumber)}
          total={totalPage}
          pageSize={itemsPerPage}
          showSizeChanger={false}
        />
      </div>
      <div className={styles['content-grid']}>
        {
          dataFiltered.length > 0 && (
            <TableCardCustom>
              <TableCardHeadCustom>
                <th>
                </th>
                <th>{t("list-groups.label-name")}</th>
                <th>{t("list-groups.label-creation-date")}</th>
                <th>{t("list-groups.label-contacts-number")}</th>     
              </TableCardHeadCustom>
              <TableCardBodyCustom>
              {
                dataFiltered.map((item, index) => (
                  <TableCardBodyTrCustom key={item.id} isSelected={isGroupSelected(item.id)}>
                    <td>
                      <TableCardButtons>
                        <a onClick={() => onDeleteClick(item)}><FontAwesomeIcon icon={ faTrash } id={`trash-icon-${index}`}/></a>
                        <a onClick={() => onHandlerGroupSelected(item)}><FontAwesomeIcon icon={ faEye } id={`eye-icon-${index}`}/></a>
                      </TableCardButtons>
                    </td>
                    <td className={styles['name-table']}>
                      {truncateText(item.groupName, MAX_CHARACTERS)}  
                    </td>
                    <td>
                      {dateFormat(item.creationDate, true)}
                    </td>
                    <td>
                      {item.fileRows}
                    </td>
                  </TableCardBodyTrCustom>            
              ))}
              </TableCardBodyCustom>
            </TableCardCustom>
          )
        }
      </div>
      <ModalConfirmation
        open={modalContactsVisible}
        title={t("list-groups.modal-delete-group-title")}
        onCancel={() => setModalContacsVisible(false)}
        onOk={handleConfirmModal}
        okBtnText={t("list-groups.modal-delete-group-btn-ok")}
      >
        <p>{modalMessage}</p>
      </ModalConfirmation> 
    </div>
  );
}
 
export default ListGroups;