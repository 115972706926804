import {useState, useEffect} from 'react';
import { Drawer, InputNumber, Select } from 'antd';
import styles from "./AssingBalance.module.css";
import MiniCard from '../MiniCard/MiniCard';
import { currencyFormat, notificationError, notificationOk } from '../../utils/helpers';
import { orangeColor, purpleColor, getGradientColor} from '../../utils/colorsCss';
import CONSTANTS from '../../utils/const';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/authRedirect';

const { Option } = Select;
const initialAssingBalanceState = {
  availableBalance: 0,
  onHoldBalance: 0
}

const AssingBalance = ({accountId, accounts, accountsBalance, open, onClose, onSave}) => {

  const [ accountsData, setAccountsData ] = useState([]);
  const [ accountName, setAccountName] = useState('');
  const [ accountBalance, setAccountBalance ] = useState(initialAssingBalanceState);
  const [ accountBalanceSelected, setAccountBalanceSelected ] = useState(initialAssingBalanceState);
  const [ defaultSelected, setDefaultSelected] = useState(CONSTANTS.LIST.DEFAULTVALUE.key);
  const [ newBalance, setNewBalance] = useState(0);
  const { auth } = useContext(AuthContext);
  const [t] = useTranslation("assing-balance");

  useEffect(() => {    
    if(accountId && accounts && accounts.length > 0) {
      
      let result = accounts.filter(ac => ac.accountId !== accountId);
      let resultMap = result.map(x => {
        return { 
          key: x.accountId,
          value: x.name
        }
      });
      resultMap.unshift(CONSTANTS.LIST.DEFAULTVALUE);

      setAccountsData(resultMap);
      setDefaultSelected(CONSTANTS.LIST.DEFAULTVALUE.key);

      let account = accounts.find(ac => ac.accountId === accountId);
      if(account){
        setAccountName(account.name);
      }
     
      let accountBalan = accountsBalance.find(ac => ac.id === accountId);
      if(accountBalan){
        setAccountBalance(accountBalan);
        setAccountBalanceSelected(initialAssingBalanceState);
        setNewBalance(0);
      }
    }
  }, [accountId, accounts])

  const onChengeAccount = (id) => {
    setDefaultSelected(id);
    if(id !== CONSTANTS.LIST.DEFAULTVALUE.key) {
      let result = accountsBalance.find(ac => ac.id === id);
      setAccountBalanceSelected(result);
    } else {
      setAccountBalanceSelected(initialAssingBalanceState);
    }
  }

  const onClick = () => {

    if(defaultSelected === CONSTANTS.LIST.DEFAULTVALUE.key) {
      notificationError(t("alert-account"));
      return;
    }

    if(newBalance === 0) {
      notificationError(t("alert-zero"));
      return;
    }

    if(newBalance > accountBalanceSelected.availableBalance) {
      notificationError(`${t("alert-less-than")} ${currencyFormat(accountBalanceSelected.availableBalance)}`);
      return;
    }

    let transferData = {
      "AccountBalanceFromId": defaultSelected,
      "AccountBalanceToId": accountId,
      "ValueToTransfer": newBalance
    }

    onSave(auth.companyId, transferData);
  }

  return ( 
    <Drawer
      title={t("title")}    
      styles={{ header: { backgroundColor: getGradientColor(orangeColor)} }}
      placement="right"
      onClose={onClose}
      open={open}
      footer={
        <div className={styles['footer']}>
          <Button onClick={onClick} type="primary" data-name="btnSave">
            {t("button-save")}
          </Button>
          <Button onClick={onClose} type="secondary" data-name="btnCancel">
            {t("button-cancel")}
          </Button>
        </div>
      }
    >
      <div className={styles['content']} data-testid="drawer-content">
        <h2>{accountName}</h2>
        <div className={styles['content-info']}>
          <MiniCard
            title={t("title-mini-card-available")}
            value={currencyFormat(accountBalance.availableBalance)}
            titleColor={orangeColor}
          />
          <MiniCard
            title={t("title-mini-card-onhold")}
            value={currencyFormat(accountBalance.onHoldBalance)}
            titleColor={purpleColor}
          />
        </div>
        <div>
          <Select
            virtual={false}
            style={{ width: 320 }}
            placeholder={"Please selected"}
            onChange={onChengeAccount}
            defaultValue={defaultSelected}
            value={defaultSelected}
          >
            {
              accountsData ? 
                accountsData.map(item =>
                  <Option key={item.key} value={item.key}>{item.value}</Option>
                ) : <></>
            }
          </Select>
        </div>
        <div className={styles['content-info']}>
          <MiniCard
            title={t("title-mini-card-available")}
            value={currencyFormat(accountBalanceSelected.availableBalance)}
            titleColor={orangeColor}
          />
          <MiniCard
            title={t("title-mini-card-onhold")}
            value={currencyFormat(accountBalanceSelected.onHoldBalance)}
            titleColor={purpleColor}
          />
        </div>
        <div className={styles['content-value']}>
          <InputNumber style={{ width: 320 }} value={newBalance} onChange={(value) => setNewBalance(value)}/>
        </div>
      </div>
      
    </Drawer>
  );
}
 
export default AssingBalance;