import React, { useEffect, useState, useContext } from 'react';
import { Select, Drawer, Input, Form, Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { orangeColor, getGradientColor, purpleColor } from "../../../utils/colorsCss";
import styles from "./accountcard-edit.css";
import HelperButton from "../../HelperButton/HelperButton";
import useSpinner from '../../../Hooks/useSpinner';
import TYPES from '../../../reducers/types';
import CONSTANTS from '../../../utils/const';
import { notificationError, notificationOk } from "../../../utils/helpers";
import AccountApi from '../../../Api/AccountApi';
import { AuthContext } from "../../../contexts/authRedirect";

const AccountEdit = ({open, onClose, account, allUsers, onEdit }) => {
    
    const [t] = useTranslation("account");
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [userOptions, setUsersOptions] = useState([]);
    const { spinnerDispacth } = useSpinner();
    
    const { auth } = useContext(AuthContext);
    useEffect(() => {
        if(open){
            form.resetFields(); 
            if (account && account.accountUsers && allUsers) {
                const filterUsers = allUsers.filter(user => user.id !== auth.userId);
                const options = filterUsers.map((user) => ({
                        label: user.displayName,
                        value: user.id
                    }));

                setUsersOptions(options);

                const filterUsersByAuth = allUsers.filter(user => user.id !== auth.userId);
                const filteredUsers = filterUsersByAuth.filter(user => account.accountUsers.includes(user.id));
                form.setFieldsValue({
                    Name: account.name || '',
                    Description: account.description || '',
                    AccountUsers: filteredUsers.map(user => user.id) || []
                });
            } else {
                form.resetFields();
                console.warn("Labels o id de labels no está disponible");
            }
        }
    }, [account, allUsers, open]);

    const handleEdit = (values) => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: true, item: CONSTANTS.SPINNERITEM.USERS }
        });
    
        const updatedAccount = {
          ...account,
          name: values.Name,
          description: values.Description,
          accountUsers: values.AccountUsers
        };
    
        onEdit(updatedAccount)
          .then(() => {
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
            });
            form.resetFields();
            onClose();
            notificationOk(t('edit-account.button-success'));
          })
          .catch(err => {
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
            });
            notificationError(t('edit-account.button-error') + err);
          });
    }

    return (
        <Drawer
            open={open}
            title={t("edit-account.label-title")}          
            styles={{ header: { backgroundColor: getGradientColor(orangeColor)} }}
            width={480}
            onClose={() => {
                form.resetFields();
                onClose();
            }}
            footer={
                <div style={{ textAlign: 'center', }} >
                    <Button onClick={() => {
                        form.resetFields();
                        onClose();
                        }}
                        style={{ marginRight: 50 }}>
                        {t("edit-account.button-cancel")}
                    </Button>
                    <Popconfirm title={t("edit-account.button-submit-confirm")}
                      okButtonProps= {{ "data-testid": "okButtonConfirm" }}
                      onConfirm={() => {
                        form
                            .validateFields()
                            .then(values => {
                                handleEdit(values);
                                form.resetFields();
                            })
                            .catch(info => {
                                console.log('Validate Failed:', info);
                            });
                        }}
                        okText="yes"
                        cancelText="no"
                    >
                        <Button type="primary" data-name="btnSave">
                            {t("edit-account.button-submit")}
                        </Button>
                    </Popconfirm>
                </div >
            }
        >
            <Form
                data-testid="drawer-content"
                form={form}
                layout="vertical"
                name="form_in_modal"
                className={styles['custom-form']}
            >
                <Form.Item
                    name="Name"
                    label={t("edit-account.label-name")}
                    rules={[
                        {
                            required: true,
                            message: 'Please input the Name!',
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="Description"
                    label={t("edit-account.label-description")}
                    rules={[
                        {
                            required: true,
                            message: 'Please input a description!',
                        }
                    ]}
                >
                    <TextArea rows={4} type='text'/>
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            {t("edit-account.label-users")}
                            <HelperButton
                                message={t("content-message.tooltip-sender-id")}
                                color={getGradientColor(purpleColor)}
                            />
                        </span>
                    }
                    name="AccountUsers"
                    rules={[
                        {
                            required: true,
                            message: 'Please select at least one user!',
                        }
                    ]}
                    >
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder={t("edit-account.placeholder-users")}
                        options={userOptions}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}

export default AccountEdit;