import React, { useEffect, useState, Fragment } from 'react';
import { Input } from 'antd';
import CardCreate from '../../components/CardCreate/CardCreate';
import UserForm from '../../components/Company/UserForm/userform';
import UserCard from '../../components/Company/UserCard/usercard';
import UserApi from '../../Api/UserApi';
import AccountApi from '../../Api/AccountApi';
import useAuth from '../../Hooks/useAuth';
import CardFilter from "../../components/CardFilter/CardFilter";
import { useTranslation } from 'react-i18next'
import useHeaderOptions from '../../Hooks/useHeaderOptions';
import TYPES from '../../reducers/types';
import CONSTANTS from '../../utils/const';
import {notificationError, notificationOk} from '../../utils/helpers';
import useSpinner from '../../Hooks/useSpinner';

const { Search } = Input;

const Users = () => {

  const [t] = useTranslation("users");
  const [users, setUsers] = useState([]);
  const [usersFiltered, setUsersFiltered] = useState([]);
  const [visible, setVisible] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const { spinnerDispacth } = useSpinner();
  const { headerOptionsDispacth } = useHeaderOptions();
  const { auth } = useAuth();

  const { getUsers, getUsersByCompany, SetUser } = UserApi();
  const { GetAccounts, GetAccountsByCompanyId } = AccountApi();

  useEffect(() => {
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.DEFAULT_OPTION,
      payload: TYPES.HEADER_OPTIONS.DEFAULT_OPTION
    });
    
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.USERS }
    });

    getInitialData().then(() => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
      }); 
    })
  }, [auth]);

  const getInitialData = async () => {
    try {

      let responseAccounts = auth.role === CONSTANTS.ROLES.ADMIN ?
        await GetAccounts() :
        await GetAccountsByCompanyId(auth.companyId);
      
      let responseUsers = await getUsersByCompany(auth.companyId);
      setUsers(responseUsers);
      setUsersFiltered(responseUsers);
      setAccounts(responseAccounts);
    } catch (error) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
      });
      console.error(error);
    }
  }

  const onCreateUser = values => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.USERS }
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let companyId = "";

    if(auth.role === CONSTANTS.ROLES.ADMIN) {
      let account = accounts.find(x => x.accountId === values.accounts);
      companyId = account.companyId;
    } else {
      companyId = auth.companyId;
    }

    let newUserCompany = {
      "companyId": companyId,
      "email": values.email,
      "firstName": values.firstname,
      "lastName": values.lastname,
      "displayName": `${values.firstname} ${values.lastname}`,
      "description": values.description,
      "initialState": true,
      "role": values.role,
      "phone": values.phone,
      "accounts": values.accounts ? [values.accounts] : []
    }

    SetUser(newUserCompany).then(result => {
      if (result.error) {
        notificationError(t("create-user.service-content-failed") + result.error);
        setVisible(false);
      } else {
        setVisible(false);
        let newUsersArray = [...users];
        newUsersArray.push(result);
        setUsers(newUsersArray);
        setUsersFiltered(newUsersArray);
        notificationOk(t("create-user.service-content-ok"));
      }

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
      });

    }).catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
      });
      notificationError(t("create-user.service-content-failed") + ' ' + error.message);
    })
  }

  const onVisibleUserForm = (isVisible) => {
    setVisible(isVisible);
  }

  const onClose = () => {
    setVisible(false);
  };

  const changeFilter = (e) => {
    let value = e.target.value;
    let result = users.filter(x => `${x.firstname} ${x.lastname}`.toLowerCase().includes(value.toLowerCase()));
    setUsersFiltered(result);
  }

  return (
    <Fragment>
      <CardFilter
        title={t("top-menu.title-top-menu")}
        filter={
          <Search
            placeholder={t("top-menu.placeholder-top-menu")}
            onChange={changeFilter}
          />
        }
        body={
          <div className="yp-grid-cards">
            <CardCreate onClick={onVisibleUserForm}
              title={t("new-user-card.title-card")}
            />
            {
              usersFiltered.map(user => (
                <UserCard key={user.id} user={user} />
              ))
            }
            <UserForm open={visible}
              accounts={accounts}
              onCreate={onCreateUser}
              onClose={onClose}
            />
          </div>
        }
      />
    </Fragment>
  );
}

export default Users;