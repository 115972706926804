import { useState } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./CampaignConsumption.module.css";
import { useEffect } from "react";
import { Spin } from "antd";
import { Column } from "@ant-design/charts";
import { each, groupBy } from '@antv/util';
import { getGradientColor, purpleColor, violetColor, magentaColor, orangeColor } from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import DataApi from "../../../../Api/DataApi";
import CONSTANTS, { CONSTANPERIODREPORTLIST } from "../../../../utils/const";

const CampaignConsumption = ({filter}) => {

  const [t] = useTranslation("home-carrier");
  const {getConsumptionCompanies} = DataApi();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);

    getCampaignConsumptionData()
    .then(response => {
      setLoading(false);

      const {chartData} = response;

      setData(chartData);
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    })
    
  }, [filter])

  const getCampaignConsumptionData = async () => {

    let data = {
      CompanyId: filter.id === CONSTANTS.COMPANYDEFAULT.companyId ? null : filter.id,
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }

    let response = await getConsumptionCompanies(filter.time, data);

    let responseData = [];
    response.forEach(x => {
      let companyName = x.companyName;
      x.itemExpenses.forEach(y => {
        responseData.push({type: y.channel, value: y.expenses, companyName: companyName})
      });
    });

    return {chartData: responseData}; 
  }

  const annotations = [];
  each(groupBy(data, 'companyName'), (values, k) => {
    const value = values.reduce((a, b) => a + b.value, 0);
    const truncatedValue = value.toFixed(2);
    annotations.push({
      type: 'text',
      position: [k, value],
      //content: `$${value} USD`,      
      content: `$${truncatedValue} USD`,
      style: {
        textAlign: 'center',
        fontSize: 14,
        fill: 'rgba(0,0,0,0.85)',
      },
      offsetY: -10,
      xField: 'companyName',
      yField: 'value',
    });
  }); 

  const maxLength = 10;

  const config = {
    axisX: {
      valueFormatString: "#,##0.00"
    },
    data,
    xField: 'companyName',
    yField: 'value',
    seriesField: 'type',
    isStack: true,
    colorField: 'type',     
    scale: {
      color: {
        range: [purpleColor, violetColor, magentaColor, orangeColor],
      }
    },
    legend: {
      position: "top",
      flipPage: false
    },
    label: {
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
      /*formatter: (datum) => {
        const { companyName } = datum;
        return companyName.length > maxLength 
          ? `${companyName.substring(0, maxLength)}...` 
          : companyName; 
      },*/
      style: {
        rotate: 0,
      }
    },
    annotations
  };

  return (
    <div className={styles['dashboard-body-card-double']}>
      <CardCustom
        style={{height: "100%", widt:"100%"}}
        title={
        <div className={styles['card-title']}>
          <p>{t("card-campaign-consumption.label-title")}</p>
          <HelperButton
            message={t("card-campaign-consumption.tooltip-title")}
            color={getGradientColor(purpleColor)}
          />
        </div>
        }
      >
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              <Column height={300} {...config} />
            </div>            
          </Spin>
        </div>
      </CardCustom>
    </div>
  );
}
 
export default CampaignConsumption;