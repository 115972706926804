import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";

export const AuthContext = React.createContext();

const AuthProvider = (props) => {

  const { instance, accounts, inProgress } = useMsal();

  const [auth, setAuthContextState] = useState({
    userId: "",
    userName: '',
    accountId: '',
    userAccounts: [],
    isLogged: false,
    companyId: '',
    role: '',
    accessToken: null
  });

  const signOut = () => {
    instance.logoutRedirect();
  }

  // Acquires and access token and then passes it to the API call
  const passTokenToApi = async (apiRequest) => {
    const request = {
      ...apiRequest,
      account: accounts[0]
    };

    try {
      // Intenta obtener el token de manera silenciosa
      const response = await instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Si no puede obtener el token silenciosamente, redirige al login
        instance.acquireTokenRedirect(request);
      } else {
        console.error("Error obteniendo el token:", error);
      }
    }
  }

  const addUserData = (data) => {
    if(data) {
      setAuthContextState({
        ...auth,
        userAccounts: data.accounts,
        companyId: data.companyId,
        role: data.role
      });
    }
  }

  useEffect(() => {
    //console.log("inProgress", inProgress);
    if (inProgress === InteractionStatus.None) {
      if (accounts.length > 0) 
      {
        const account = accounts[0];
        //console.log("Usuario ya registrado", account);
        setAuthContextState({
          ...auth,
          userId: account.localAccountId,
          userName: account.username,
          accountId: account.homeAccountId,
          isLogged: true
        });
      } else {
        console.log("Redirect");
        instance.loginRedirect();
      }
    }

  }, [inProgress, accounts, instance]);

  return (
    <AuthContext.Provider value={{ auth, signOut, passTokenToApi, addUserData }}>
      {props.children}
    </AuthContext.Provider>
  );
}
export default AuthProvider