import { useTranslation } from "react-i18next";
import BillingSms from "./BillingSms/BillingSms";
import styles from "./CarrierHome.module.css";
import BillingVoice from "./BillingVoice/BillingVoice";
import BillingShortlink from "./BillingShortlink/BillingShortlink";
import { Link } from "react-router-dom";
import ChannelSelect from "../../ChannelSelect/ChannelSelect";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST } from "../../../utils/const";
import { useState } from "react";
import CarrierFilters from "../../DashboardFilters/CarrierFilters/CarrierFilters";
import CompanyApi from "../../../Api/CompanyApi";
import { useEffect } from "react";
import TotalSms from "./TotalSms/TotalSms";
import TotalVoice from "./TotalVoice/TotalVoice";
import TotalConversionRate from "./TotalConversionRate/TotalConversionRate";
import TotalCampaigns from "./TotalCampaigns/TotalCampaigns";
import CampaignsByCountry from "./CampaignsByCountry/CampaignsByCountry";
import CampaignConsumption from "./CampaignConsumption/CampaignConsumption";
import ChannelExpenses from "./ChannelExpenses/ChannelExpenses";
import TopCompanyConsumptions from "./TopCompanyConsumptions/TopCompanyConsumptions";

const FILTERINITIALDATA = {channel: CHANELLIST.DEFAULT, id: CONSTANTS.COMPANYDEFAULT.companyId, time: CONSTANPERIODREPORTLIST.CURRENTWEEK.key, date: []};

const CarrierHome = () => {

  const [t] = useTranslation("home-carrier");
  const [ channelSelected, setChannelSelected ] = useState(CHANELLIST.DEFAULT);
  const [ companies, setCompanies]  = useState([]);
  const [ filter, setFilter ] = useState(FILTERINITIALDATA)

  const { GetCompanies } = CompanyApi();

  useEffect(() => {
    getCompaniesProccess()
      .then(response => {
        if(response) {
          let responseMap = response.map(x => { return { companyId: x.companyId, name: x.companyName}});
          responseMap.unshift(CONSTANTS.COMPANYDEFAULT);
          setCompanies(responseMap);
        }
      })
  }, [])

  const getCompaniesProccess = async () => {
    let response = await GetCompanies();
    return response
  }

  const onSelectChannel = (channel) => {
    setChannelSelected(channel);
    filter.channel = channel;
    setFilter(filter);
  }

  const onChangeFilter = (filter) => {
    filter.channel = channelSelected;
    setFilter(filter);
  }

  const render = () => {
    switch (channelSelected.key) {
      case CHANELLIST.DEFAULT.key:
        return <>
            <TotalSms filter={filter}/>
            <TotalVoice filter={filter}/>
            <TotalConversionRate filter={filter}/>
            <TotalCampaigns filter={filter}/>
            <CampaignsByCountry filter={filter}/>
            <CampaignConsumption filter={filter}/>
            <ChannelExpenses filter={filter}/>
            <TopCompanyConsumptions filter={filter}/>
          </>;
      case CHANELLIST.VOICE.key:
        return <>
            <TotalVoice filter={filter}/>
            <TotalCampaigns filter={filter}/>
            <ChannelExpenses filter={filter}/>
          </>; 
      case CHANELLIST.SMS.key:
        return <>
            <TotalSms filter={filter}/>
            <TotalConversionRate filter={filter}/>
            <TotalCampaigns filter={filter}/>
            <ChannelExpenses filter={filter}/>
          </>;     
      default:
        return <></>
    }
  }

  return (
    <div className={styles['dashboard-home']}>
      <div className={styles['dashboard-header']}>
        <div className={styles['dashboard-header-cards']}>
          <BillingSms />
          <BillingVoice />
          <BillingShortlink />
        </div>
        <div className={styles['dashboard-header-buttons']}>
          <Link to="/Companies" className={styles['dashboard-header-button']}>{t("dashboard-header-buttons.add-balance")}</Link>
        </div>
      </div>
      <div className={styles['dashboard-menu']}>
        <ChannelSelect onSelect={onSelectChannel}/>
        <CarrierFilters companies={companies} onChangeFilter={onChangeFilter}/>
      </div>
      <div className={styles['dashboard-body']}>
      {
        render()
      }
      </div>
    </div>
  );
}
 
export default CarrierHome;